import React, { useEffect, useRef, useState } from 'react'

import { Modal } from '../components/layout/Modal'
import { PaymentModal } from '../components/payment/PaymentModal'

import {
  AffiliateBenefit,
  CouponBenefit,
  DefaultBenefit,
  InviteBenefit,
  PaymentOrderRequest,
} from '../service/payment'

import useGetPlansPrice from '../hooks/query/plans/useGetPlansPrice'
import { subscriptionType } from '../service/plans'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from '../components/atom/Button'
import { FreePlanModal } from '../components/FreePlanModal'
import classNames from 'classnames'
import useGetAutoChargeList from '../hooks/query/point/useGetAutoChargeList'
import { CashPaymentModal } from '../components/payment/CashPaymentModal'
import { useGetMyPoint } from '../hooks/query/point/useGetMyPoint'
import { RequestPurchasePoint } from '../service/point'
import { useGetPointOrderId } from '../hooks/query/point/usetGetPoinOrderId'
import { useSaveUtm } from '../hooks/query/useSaveUtm'
import { PromotionBanner } from '../components/affiliate/PromotionBanner'
import { isMobile } from 'react-device-detect'
import useUserStore from '../store/useUserStore'
import usePrevStore from '../store/usePrevStore'
import { savePrevPath } from '../utils/common'

export const ShortFormPriceGuide = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const payment = params.get('payment')
  const { data: myPointData, refetch: getMyPointRefetch } = useGetMyPoint()
  const { mutate: pointOrderIdMutate } = useGetPointOrderId()

  const userInfo = useUserStore((state: any) => state.userInfo)
  const prevSet = usePrevStore((state: any) => state.set)
  const navigate = useNavigate()

  const { data: autoChargeList, isPending } = useGetAutoChargeList()
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [isUpgrade, setUpgrade] = useState<boolean>(false)
  const [monthPriceValue, setMonthPriceValue] = useState(0)
  const [isPlan, setIsPlan] = useState<boolean>(false)
  const [isCashPaymentModal, setCashPaymentModal] = useState<boolean>(false)
  const [type, isType] = useState('annual')
  const [planIdValue, setPlanId] = useState(0)
  const [isToggled, setIsToggled] = useState(false)
  const [isMonth, setIsMonth] = useState<boolean>(false)
  const [isFreePlanModal, SetFreePlanModal] = useState<boolean>(false)
  const [requestPurchasePoint, setRequestPurchasePoint] =
    useState<RequestPurchasePoint>({ orderId: '' })
  const [paymentOrderRequest, setPaymentOrderRequest] =
    useState<PaymentOrderRequest>({
      orderId: '',
      productName: '',
      subscriptionType: '',
      price: 0,
      vat: 0,
      id: 0,
    })
  const [affiliateBenefit, setAffiliateBenefit] =
    useState<null | AffiliateBenefit>(null)
  const [couponBenefit, setCouponBenefit] = useState<null | CouponBenefit>(null)
  const [defaultBenefit, setDefaultBenefit] = useState<null | DefaultBenefit>(
    null
  )

  const [inviteBenefit, setInviteBenefit] = useState<null | InviteBenefit>(null)
  // Plan 및 subscriptionType에 따른 가격을 가져오는 쿼리

  const {
    isLoading: priceIsLoading,
    data: price,
    refetch: priceRefetch,
  } = useGetPlansPrice(type)

  const handleCashPaymentModal = () => {
    if (userInfo) {
      pointOrderIdMutate(undefined, {
        onSuccess: (data) => {
          setRequestPurchasePoint(data)
          setCashPaymentModal(true)
        },
        onError: () => {},
      })
    } else {
      savePrevPath('/shortform-price-guide?payment=true', prevSet)
      navigate('/sign-up')
    }
  }
  useEffect(() => {
    if (isMonth) {
      isType(subscriptionType.MONTHLY)
    } else {
      isType(subscriptionType.ANNUAL)
    }
  }, [isToggled])

  useEffect(() => {
    priceRefetch()
  }, [type])

  useEffect(() => {
    if (paymentModal || isFreePlanModal) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [paymentModal, isFreePlanModal])

  useEffect(() => {
    if (payment && userInfo) {
      params.delete('payment')
      const updatedSearch = params.toString()
      const updatedPath = `${location.pathname}${
        updatedSearch ? `?${updatedSearch}` : ''
      }`
      navigate(updatedPath, { replace: true })
      handleCashPaymentModal()
    }
  }, [payment, userInfo])

  return (
    <div className="md:max-w-[1000px] max-w-[375px]  my-0 mx-auto flex flex-col items-center md:gap-[20px] gap-[40px]">
      {userInfo?.affiliate.point && (
        <PromotionBanner
          daysLeft={userInfo?.affiliate?.point?.daysLeft}
          hoursLeft={userInfo?.affiliate?.point?.hoursLeft}
          affiliateImage={userInfo?.affiliate?.point?.affiliateImage}
          affiliateDescription={
            userInfo?.affiliate?.point?.affiliateDescription
          }
        />
      )}

      <div className="flex w-[343px] md:w-full flex-col items-center ">
        <p className="md:text-[40px] text-[32px] font-bold">
          컨텐츠 영상 가격 안내
        </p>
        {price?.benefitType === 'invite' && !userInfo?.alreadyBenefit && (
          <div className="w-[335px] text-[14px] md:text-base md:w-[460px] h-[42px] mt-5">
            <button
              className="text-white font-[800] flex items-center justify-center w-full rounded-[40px] h-full "
              style={{
                fontFamily: 'Tenada',
                background:
                  'linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))',
              }}
            >
              <span className="mt-0.5">
                {' '}
                지금 바로 첫 결제하시면,10% 추가 캐시 적립!
              </span>
            </button>
          </div>
        )}
        {userInfo?.affiliate.point && (
          <PromotionBanner
            daysLeft={userInfo?.affiliate?.point?.daysLeft}
            hoursLeft={userInfo?.affiliate?.point?.hoursLeft}
            affiliateImage={userInfo?.affiliate?.point?.affiliateImage}
            affiliateDescription={
              userInfo?.affiliate?.point?.affiliateDescription
            }
            className="mt-5"
          />
        )}
        <div className="bg-[#F6F8FB] md:px-[32px]  p-5 flex flex-col rounded-[20px] mt-10 md:w-[936px]">
          <div className="font-bold md:text-[24px]">
            <p>
              <span className="text-main underline">컨텐츠 영상</span>은{` `}
              <span className="text-main underline">패스트컷 캐시</span>를{` `}
              사용해서 생성할 수 있어요.{!isMobile && <br />} 사용하실 효과들을
              고려해서 충전 단위를 선택하고,
              {` `}
              <span className="text-main">보너스 캐시</span>까지 얻어보세요!
            </p>
          </div>
          <div className="bg-[#D2F8F8] px-4 py-4 flex md:gap-4 gap-2 rounded-[10px] mt-4 md:items-center">
            <div className="md:w-[39px] md:h-[46px] w-[25px] h-[28px]">
              <img
                src="assets/img/cash_price_guide.png"
                alt="x"
                width={39}
                height={46}
              />
            </div>
            <p className="md:text-base text-[14px] md:w-auto w-[231px]">
              컨텐츠 영상 효과들 중에서 캐시 아이콘이 붙어있는 항목을 사용하려면
              캐시가 필요해요. <br />
              항목별로 몇 캐시인지는 컨텐츠 영상 만들기 화면에서 확인하세요!{' '}
            </p>
          </div>
          <div className="bg-white mt-4 p-5 md:p-7 rounded-[10px] text-[16px] md:text-[18px]">
            {autoChargeList?.chargeMap?.total?.map((el, index) => (
              <>
                <div className="flex gap-[8px] items-center justify-between">
                  <p className="font-bold flex items-center">
                    {Number(el.chargeAmount).toLocaleString()}캐시
                    {Number(el.chargeAmount) === 100000 && (
                      <span className="bg-main text-white rounded-[4px] md:px-3 px-1 py-0.5 md:py-[4px] md:text-[18px] text-[10px] font-bold md:ml-2 ml-1  ">
                        인기
                      </span>
                    )}
                  </p>
                  {Number(el.bonusRate) > 0 && (
                    <div className="flex flex-col items-end text-[12px] md:text-[18px]">
                      <p className="font-bold ">
                        {' '}
                        + {`${el.bonus.toLocaleString()}`} 캐시 보너스
                      </p>
                      <p className="md:text-[14px] text-[12px]">
                        {autoChargeList.benefitInfo?.affiliateDescription &&
                          '제휴 혜택'}
                        {autoChargeList.benefitInfo?.inviteCode && '초대 코드'}
                        {autoChargeList.benefitType === 'purchase' &&
                          '충전 혜택'}
                        {` `}
                        <span
                          className={classNames(
                            'font-bold px-1 rounded-[4px]',
                            {
                              'text-error bg-[#EEF1F7]':
                                Number(el.bonusRate) <= 9,
                              'bg-error text-white': Number(el.bonusRate) > 9,
                            }
                          )}
                        >
                          {`${Number(el.bonusRate).toFixed(0)}%`}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                {index !== autoChargeList.chargeMap.total.length - 1 && (
                  <hr className="h-[1px] bg-[#EEF1F7] my-[20px]" />
                )}
              </>
            ))}
          </div>
          <div className="flex mt-4" onClick={handleCashPaymentModal}>
            <Button className="w-full bg-main py-[19px] text-white rounded-[40px] font-bold text-[18px]">
              캐시 충전하기
            </Button>
          </div>
        </div>
      </div>
      <div className="md:w-[1000px] w-[335px] md:h-[208px] p-5 md:px-[32px] md:py-[16px] flex flex-col md:flex-row items-center justify-between border-[2px] border-[#735CFF] rounded-[20px]">
        <div className="flex flex-col items-center">
          <p className="font-bold md:text-[24px] text-[16px]">커스텀 솔루션</p>
          <p className="font-[800] text-[#735CFF]  md:text-[32px] text-[20px]">
            고객 맞춤 플랜
          </p>
        </div>
        <div className="md:text-[18px] text-[14px] font-bold flex flex-col gap-[16px] mt-[32px] md:mt-0">
          <p className="flex items-center gap-2 md:text-[18px] text-[14px]">
            {' '}
            <img
              src="assets/img/check_circle_purple.png"
              alt="check"
              width={24}
              height={24}
            />
            브랜드, 플랫폼, 에이전시 고객을 위한 맞춤형 솔루션
          </p>
          <p className="flex items-center gap-2 md:text-[18px] text-[14px]">
            {' '}
            <img
              src="assets/img/check_circle_purple.png"
              alt="check"
              width={24}
              height={24}
            />
            고객의 필요에 따라 사이트 고객 맞춤 작업을 지원
          </p>
          <p className="flex items-center gap-2 md:text-[18px] text-[14px]">
            {' '}
            <img
              src="assets/img/check_circle_purple.png"
              alt="check"
              width={24}
              height={24}
            />
            <a
              href="https://angelleague.notion.site/AI-115ba20e97f3803d91cdc2c647149c3e"
              target="_blank"
              rel="noreferrer"
              className="text-[#735CFF] underline"
            >{`고객 맞춤 플랜 상세 내용 확인 >>`}</a>
          </p>
        </div>
        <div className="flex flex-col gap-[8px] font-bold text-[18px] mt-[32px] md:mt-0">
          <a
            href="http://pf.kakao.com/_CFxfHxj/chat"
            target="_blank"
            rel="noreferrer"
            className="w-full"
          >
            <button
              className="w-[264px] h-[60px]  border-[#735CFF] border-[2px] rounded-[40px] bg-[#735CFF] text-white text-[18px]"
              onClick={() => {}}
            >
              카톡으로 문의하기
            </button>
          </a>
        </div>
      </div>

      {paymentModal && (
        <PaymentModal
          setPaymentModal={setPaymentModal}
          paymentOrderRequest={paymentOrderRequest}
          affiliateBenefit={affiliateBenefit}
          inviteBenefit={inviteBenefit}
          affiliatePlan={userInfo?.affiliate?.plan}
          couponBenefit={couponBenefit}
          defaultBenefit={defaultBenefit}
          setCouponBenefitData={setCouponBenefit}
          setAffiliateBenefitData={setAffiliateBenefit}
          setDefaultBenefitData={setDefaultBenefit}
          userInfo={userInfo?.userInfo}
          planId={planIdValue}
          type={type}
          upgrade={isUpgrade}
          monthPrice={monthPriceValue}
        />
      )}

      {isCashPaymentModal && (
        <CashPaymentModal
          setCashPaymentModal={setCashPaymentModal}
          userInfo={userInfo?.userInfo}
          affiliatePoint={userInfo?.affiliate?.point}
          userCurrentPoint={myPointData.totalPoint}
          requestPurchasePoint={requestPurchasePoint}
        />
      )}

      {isPlan && (
        <Modal isModal={isPlan}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">
                이미 사용중인 플랜이 있어요!
              </p>
              <div>
                <p className="">
                  사용중인 플랜의 기한이 지난 후<br /> 새로운 플랜을
                  결제해주시거나 <br />
                  패스트컷에 따로 문의해주세요!
                </p>
              </div>
            </div>
          </div>
          <div className="w-[400px] bg-main h-[60px] border-b border-main rounded-b-[20px] flex justify-center items-center ">
            <button
              className="text-white w-[400px] font-bold"
              onClick={() => {
                setIsPlan(false)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}

      {isFreePlanModal && (
        <FreePlanModal
          isFreePlanModal={isFreePlanModal}
          setFreePlanModal={SetFreePlanModal}
        />
      )}
    </div>
  )
}
