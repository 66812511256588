import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import useGetPlansPrice from '../../hooks/query/plans/useGetPlansPrice'
import classNames from 'classnames'
import { CircularCheckbox } from '../CircularCheckbox'
import useGetPlans from '../../hooks/query/plans/useGetPlans'
import { UserType } from '../../utils/common'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AffiliateBenefit,
  CouponBenefit,
  DefaultBenefit,
  InviteBenefit,
  PaymentOrderRequest,
} from '../../service/payment'
import useGetPaymentOrderRequests from '../../hooks/query/useGetPaymentOrderRequests'
import { PaymentModal } from '../payment/PaymentModal'
import { SubscriptionPlan, subscriptionType } from '../../service/plans'
import { BrandPaymentModal } from './BrandPaymentModal'
import useGetBillingCustomerKey from '../../hooks/query/brandpay/useGetBillingCustomerKey'

interface PlanPriceModalProps {
  isPlanPriceModal: boolean
  setPlanPriceModal: Dispatch<SetStateAction<boolean>>
  userInfo: any
}

export const PlanPriceModal = ({
  children,
  isPlanPriceModal,
  setPlanPriceModal,
  userInfo,
}: PropsWithChildren<PlanPriceModalProps>) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [paymentOrderRequest, setPaymentOrderRequest] =
    useState<PaymentOrderRequest>({
      orderId: '',
      productName: '',
      subscriptionType: '',
      price: 0,
      vat: 0,
      id: 0,
    })
  const [type, setType] = useState('annual')
  const [customerKey, setCustomerKey] = useState<string>('')
  const [selectedPlanId, setSelectPlanId] = useState(0)
  const [selectedPlanPiceId, setSelectPlanPriceId] = useState(0)
  const [selectPlanPrice, setPlanPrice] = useState<any>({})
  const [isPlan, setIsPlan] = useState<boolean>(false)
  const {
    isLoading: priceIsLoading,
    data: planPriceInfo,
    refetch: priceRefetch,
  } = useGetPlansPrice(type)
  const { isLoading, data: plans, refetch } = useGetPlans()
  const { mutate: customerKeyMutate } = useGetBillingCustomerKey()
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [brandPaymentModal, setBrandPaymentModal] = useState<boolean>(false)
  const [affiliateBenefit, setAffiliateBenefit] =
    useState<null | AffiliateBenefit>(null)
  const [couponBenefit, setCouponBenefit] = useState<null | CouponBenefit>(null)
  const [defaultBenefit, setDefaultBenefit] = useState<null | DefaultBenefit>(
    null
  )
  const [inviteBenefit, setInviteBenefit] = useState<null | InviteBenefit>(null)
  const { mutate: getPaymentOrderRequestsMutate } = useGetPaymentOrderRequests()

  const handleBrandPay = (
    planId: number,
    planPriceId: number,
    planPrice: SubscriptionPlan | null
  ) => {
    setSelectPlanId(planId)
    setSelectPlanPriceId(planPriceId)
    if (!userInfo?.userInfo) {
      navigate('/sign-up')
      return
    } else if (type !== 'monthly') return
    getPaymentOrderRequestsMutate(
      { planId, planPriceId },
      {
        onSuccess: (data) => {
          customerKeyMutate('', {
            onSuccess: (customerKey) => {
              setCustomerKey(String(customerKey))
              setPaymentOrderRequest(data.orderRequest)
              if (data.affiliateBenefit) {
                setAffiliateBenefit(data.affiliateBenefit)
              }
              if (data?.couponBenefit) {
                setCouponBenefit(data.couponBenefit)
              }
              if (data?.inviteBenefit) {
                setInviteBenefit(data.inviteBenefit)
              }
              setBrandPaymentModal(true)
            },
            onError: (e) => {
              console.log('customerKey 발급 or 획득 실패')
            },
          })
        },
        onError: (e) => {
          console.log(e)
        },
      }
    )
  }

  const getPaymentOrderRequests = (
    planId: number,
    planPriceId: number,
    planPrice: SubscriptionPlan | null
  ) => {
    if (planId === 0 || planPriceId === 0) return
    setSelectPlanId(planId)
    if (!userInfo?.userInfo) {
      navigate('/sign-up')
      return
    }

    if (
      // 월결제 달성 후 다시 월결제 조건
      userInfo?.usage?.isDone &&
      userInfo?.subscriptions?.planId === planId
    ) {
    } else {
      if (
        !(
          userInfo?.userType === UserType.USER ||
          userInfo?.userType === UserType.체험
        )
      ) {
        setIsPlan(true)
        return
      }
    }

    getPaymentOrderRequestsMutate(
      { planId, planPriceId },
      {
        onSuccess: (data) => {
          setPaymentOrderRequest(data.orderRequest)
          if (data.affiliateBenefit) {
            setAffiliateBenefit(data.affiliateBenefit)
          }
          if (data?.couponBenefit) {
            setCouponBenefit(data.couponBenefit)
          }
          if (data?.inviteBenefit) {
            setInviteBenefit(data.inviteBenefit)
          }
          if (data?.defaultBenefit && type === 'annual') {
            setDefaultBenefit(data.defaultBenefit)
          }
          setPaymentModal(true)
        },
        onError: (e) => {
          console.log(e)
        },
      }
    )
  }

  const paybackCoupon = () => {
    if (!userInfo) {
      // 로그인 전
      return true
    } else if (
      // 로그인 후 체험이면서 사용전 / 일반유저
      (userInfo &&
        userInfo?.userType === UserType.체험 &&
        Number(userInfo?.usage?.usedCount) === 0) ||
      userInfo?.userType === UserType.USER
    ) {
      return true
    } else if (userInfo?.subscriptions?.subscriptionType === 'monthly') {
      //유저가 월간 플랜을 사용중
      return true
    }
    return false
  }

  const changeType = (selectType: string) => {
    setType(selectType)
  }
  useEffect(() => {
    priceRefetch()
    setSelectPlanId(0)
    setSelectPlanPriceId(0)
    setPlanPrice({})
  }, [type])

  useEffect(() => {
    if (planPriceInfo) {
      const { prices } = planPriceInfo

      setSelectPlanId(prices[prices.length - 2].id)
      setSelectPlanPriceId(
        prices[prices.length - 2].planPrice[
          prices[prices.length - 1].planPrice.length - 1
        ].id
      )
      //   setPlanPrice(
      //     prices[prices.length - 2].planPrice[
      //       prices[prices.length - 2].planPrice.length - 2
      //     ]
      //   )
    }
  }, [planPriceInfo])

  if (!isPlanPriceModal) return null
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
      <div className="bg-white w-[335px] h-[400px] shadow-main rounded-t-[20px] px-5 pt-8  flex flex-col overflow-y-auto  pb-[30px] gap-4 ">
        {/*//*top */}
        <div>
          <p className="flex justify-end">
            <button
              onClick={() => {
                setPlanPriceModal(false)
              }}
              className=""
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                height={20}
                width={20}
              />
            </button>
          </p>
          {pathname !== '/challenge' && (
            <p className="font-bold text-left">
              지금 상품 영상 플랜 사용을 시작하세요!
              <br />
              영상 생성, 미션 참여 등이 가능해져요.
            </p>
          )}
        </div>
        {/*//*가격 플랜 body */}
        <div className="flex flex-col gap-4">
          {pathname !== '/challenge' && (
            <div className="flex h-[33px]">
              <div className="flex relative w-[230px]">
                <button
                  className={classNames(
                    ' h-[33px]  font-bold text-[14px] absolute left-0 rounded-[10px]',
                    {
                      'text-white bg-[#FD6767] w-[160px] z-50':
                        type === 'annual',
                      'bg-[#EEF1F7] text-[#8F929B] w-[142px] z-0':
                        type !== 'annual',
                    }
                  )}
                  onClick={() => {
                    changeType('annual')
                  }}
                >
                  연 결제 ~40% 할인
                </button>
                <button
                  className={classNames(
                    'w-[88px] h-[33px]  font-bold text-[14px] absolute right-0 rounded-[10px]',
                    {
                      'text-white bg-[#FD6767] ] w-[98px] z-50':
                        type === 'monthly',
                      'bg-[#EEF1F7] text-[#8F929B]  z-0': type !== 'monthly',
                    }
                  )}
                  onClick={() => {
                    changeType('monthly')
                  }}
                >
                  월 구독
                </button>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-1">
            {Array.isArray(planPriceInfo?.prices) &&
              planPriceInfo?.prices
                .slice()
                .reverse()
                .map((plan, i) => {
                  if (plan.name === '체험') return ''
                  if (pathname === '/challenge' && plan.name === '라이트')
                    return ''
                  return (
                    <>
                      <div className="flex items-center border-[1px] border-[#EEF1F7] rounded-[20px] p-5">
                        <CircularCheckbox
                          id={i}
                          checked={plan.id === selectedPlanId}
                          onChange={() => {
                            setSelectPlanId(Number(plan.id))
                            setSelectPlanPriceId(plan.planPrice[0].id)
                            setPlanPrice(plan.planPrice[0])
                          }}
                          label={''}
                        />
                        <div className="flex flex-col ">
                          <p className="font-bold text-main  flex gap-[2px]">
                            {plan?.name} 플랜
                            {plan.name === '프리미엄' && (
                              <div>
                                <div className="h-[4px]"></div>
                                <div className="text-[10px] font-bold bg-error text-[#FFFFFF] rounded-[4px]  h-[12px] flex items-center p-[2px]">
                                  <div>BEST</div>
                                </div>
                              </div>
                            )}
                          </p>
                          {planPriceInfo?.benefitType === 'invite' && (
                            <p className="text-error font-bold">
                              {' '}
                              첫 결제 +{' '}
                              {`${planPriceInfo.prices[
                                i
                              ].planPrice[0].bonusPoint?.toLocaleString()}`}{' '}
                              캐시 보너스
                            </p>
                          )}
                          {type === 'annual' && (
                            <p className="text-left">
                              {' '}
                              <s className="font-bold text-[#8F929B]">
                                ₩
                                {!!planPriceInfo?.prices?.length &&
                                  plan?.planPrice[0]?.monthlyPrice?.toLocaleString()}
                                {` `}
                              </s>{' '}
                              <s className="text-[16px] text-[#8F929B] font-bold">
                                /월
                              </s>
                            </p>
                          )}
                          {type === 'annual' ? (
                            <>
                              <p className="text-left">
                                <span className="font-[800] text-[20px]">
                                  ₩
                                  {!!planPriceInfo?.prices?.length &&
                                    Number(
                                      plan?.planPrice[0].descriptionPrice
                                    ).toLocaleString()}
                                </span>
                                <span className="font-bold text-[12px]">
                                  {' '}
                                  /월(VAT 별도)
                                </span>
                              </p>
                            </>
                          ) : (
                            <>
                              {' '}
                              <p className="text-left">
                                <span className="font-[800] text-[20px]">
                                  ₩
                                  {!!planPriceInfo?.prices?.length &&
                                    Number(
                                      plan?.planPrice[0].price
                                    ).toLocaleString()}
                                </span>
                                <span className="font-bold text-[12px]">
                                  {' '}
                                  /월(VAT 별도)
                                </span>
                              </p>
                            </>
                          )}
                          {type === 'annual' && (
                            <span className="text-[12px] text-[#8F929B] text-left">
                              ₩
                              {!!planPriceInfo?.prices?.length &&
                                Number(
                                  plan?.planPrice[0].price
                                ).toLocaleString()}
                              /1년
                            </span>
                          )}

                          {type === 'annual' &&
                            plan.name !== '라이트' &&
                            paybackCoupon() && (
                              <div
                                className={classNames(
                                  'flex h-[48px] mt-2 cursor-pointer'
                                )}
                              >
                                {' '}
                                {
                                  <img
                                    src="assets/img/coupon/payback.png"
                                    alt=""
                                    className="w-[119px] h-[48px]"
                                  />
                                }
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  )
                })}
          </div>
        </div>
      </div>
      <div className="flex justify-between sticky bottom-0 h-[92px] shadow-main bg-white w-[335px] items-center rounded-b-[20px] p-[15px] font-bold">
        <button
          className=" rounded-full w-[149.5px] h-[44px] flex justify-center items-center  bg-[#EEF1F7]  text-[#5E6068]"
          onClick={() => {
            setPlanPriceModal((prev) => !prev)
            navigate('/price-guide')
          }}
        >
          플랜 상세 보기
        </button>
        <button
          className=" rounded-full w-[149.5px] h-[44px] flex justify-center items-center bg-main  text-white"
          onClick={() => {
            if (type === subscriptionType.ANNUAL) {
              getPaymentOrderRequests(
                selectedPlanId,
                selectedPlanPiceId,
                selectPlanPrice
              )
            } else if (type === subscriptionType.MONTHLY) {
              handleBrandPay(
                selectedPlanId,
                selectedPlanPiceId,
                selectPlanPrice
              )
            }
          }}
        >
          사용하기
        </button>
      </div>
      {paymentModal && (
        <PaymentModal
          setPaymentModal={setPaymentModal}
          paymentOrderRequest={paymentOrderRequest}
          affiliateBenefit={affiliateBenefit}
          inviteBenefit={inviteBenefit}
          affiliatePlan={userInfo?.affiliate?.plan}
          couponBenefit={couponBenefit}
          defaultBenefit={defaultBenefit}
          setCouponBenefitData={setCouponBenefit}
          setAffiliateBenefitData={setAffiliateBenefit}
          setDefaultBenefitData={setDefaultBenefit}
          userInfo={userInfo?.userInfo}
          planId={selectedPlanId}
          type={type}
          upgrade={false}
          monthPrice={0}
        />
      )}
      {brandPaymentModal && (
        <BrandPaymentModal
          setPaymentModal={setBrandPaymentModal}
          paymentOrderRequest={paymentOrderRequest}
          affiliateBenefit={affiliateBenefit}
          inviteBenefit={inviteBenefit}
          affiliatePlan={userInfo?.affiliate?.plan}
          couponBenefit={couponBenefit}
          defaultBenefit={defaultBenefit}
          setCouponBenefitData={setCouponBenefit}
          setAffiliateBenefitData={setAffiliateBenefit}
          setDefaultBenefitData={setDefaultBenefit}
          userInfo={userInfo?.userInfo}
          planId={selectedPlanId}
          planPriceId={selectedPlanPiceId}
          type={type}
          customerKey={customerKey}
        />
      )}
    </div>
  )
}
