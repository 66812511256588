import { useMutation } from '@tanstack/react-query'
import {
  getBillingCustomerKey,
  putBillingMethods,
} from '../../../service/brandpay'

function usePutBillingMethods() {
  return useMutation({
    mutationKey: ['put-billing-customerkey'],
    mutationFn: putBillingMethods,
  })
}

export default usePutBillingMethods
