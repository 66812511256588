import { useQuery } from '@tanstack/react-query'
import { getOutro } from '../../service/list'

function useGetOutro() {
  return useQuery({
    queryKey: ['get-outro'],
    queryFn: () => getOutro(),
    staleTime: 6 * 1000,
  })
}

export default useGetOutro
