import API from './api'

export interface BillingMethodsType {
  id: 3
  methodIconUrl: string
  methodName: string
  methodNumber: string
  methodStatus: string
  selected: number
}

export type BillingTransactionItemType = {
  userId: number
  amount: string
  status: string
  type: string
  createdAt: string
}
export interface BillingTransactionType {
  totalCount: number
  data: BillingTransactionItemType[]
}
export async function getBillingCustomerKey(data: any) {
  try {
    return await API.get('/billing/customer-key', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getBillingMethods(): Promise<BillingMethodsType[]> {
  try {
    return await API.get('/billing/billing-methods', {})
  } catch (error: any) {
    console.log(error?.response?.data)
    throw error
  }
}

export async function putBillingMethods(
  billingMethodDetailId: number
): Promise<BillingMethodsType[]> {
  try {
    return await API.put(
      `/billing/billing-methods/${billingMethodDetailId}`,
      {}
    )
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function brandPayTransaction(data: any) {
  try {
    return await API.post('/payment/brand-pay-transaction', data)
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getBillingTransactions(
  page: number,
  pageSize: number
): Promise<BillingTransactionType> {
  try {
    return await API.get(
      `/billing/billing-transactions?page=${page}&perPage=${pageSize}`,
      {}
    )
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function putBillingDisable(data: any) {
  try {
    return await API.put(`/billing/billing-methods-disable`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function putBillingEnable(data: any) {
  try {
    return await API.put(`/billing/billing-methods-enable`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}
