import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Button } from '../atom/Button'
import { PopContentType } from '../../service/list'
import { setCookie } from '../../utils/common'
import { useCookies } from 'react-cookie'
import { isMobile } from 'react-device-detect'
import { Modal } from '../layout/Modal'

interface FailWithShortModalProps {
  isFailWithShortModal: boolean
  setFailWithShortModal: Dispatch<SetStateAction<boolean>>
  handleScroll: () => void
}

export const FailWithShortModal = ({
  children,
  isFailWithShortModal,
  setFailWithShortModal,
  handleScroll,
}: PropsWithChildren<FailWithShortModalProps>) => {
  return (
    <>
      <Modal isModal={isFailWithShortModal}>
        <div className="bg-white w-[335px] h-auto p-[20px] border-t rounded-t-[20px]">
          <div className="flex flex-col items-center justify-center h-full gap-[24px]">
            <p className="font-bold text-[18px]">
              🚨 컨텐츠 영상 생성용 캐시 부족
            </p>
            <div className="flex flex-col leading-[22px]">
              <p className="text-[14px]">
                컨텐츠 영상 생성을 위해 캐시가 필요합니다.
                <br /> 부족한 캐시를 충전하신 후<br /> 다시 생성 요청
                부탁드립니다.
              </p>
              <p className=" text-[14px] text-center my-6">
                아래 <span className="font-bold text-main">‘캐시충전'</span>{' '}
                버튼을 누르시면
                <br />
                <span className="font-bold text-main"> 브라우저 새 탭</span>이
                열려 캐시를 충전하실 수 있으며,
                <br />
                캐시 충전 후 이 탭으로 돌아오시면
                <br />
                <span className="font-bold text-main">
                  {' '}
                  지금 입력하신 영상 정보들은 이 탭에 그대로 유지
                </span>
                되니
                <br />
                걱정마세요! 🤗
              </p>
            </div>
          </div>
        </div>
        <div className="w-[335px] text-[14px]  h-[60px]  flex justify-center items-center">
          <button
            className=" w-[50%] h-full text-black bg-[#EEF1F7] rounded-bl-[20px] border-b border-[#EEF1F7] "
            onClick={() => {
              setFailWithShortModal((prev) => !prev)
              handleScroll()
            }}
          >
            취소
          </button>
          <button
            className="text-white w-[50%] h-full bg-main rounded-br-[20px] border-b border-main font-bold"
            onClick={() => {
              setFailWithShortModal((prev) => !prev)
            }}
          >
            <a
              className="w-full h-full flex items-center justify-center" // 추가
              href={`${process.env.REACT_APP_URL}/shortform-price-guide?payment=true&close=true`}
              target="_blank"
              rel="noreferrer"
            >
              <span>캐시충전</span>
            </a>
          </button>
        </div>
      </Modal>
    </>
  )
}
