import { UserInfo } from 'os'
import { create } from 'zustand'
import { FastCutProfile } from '../service/user'

// 공동 사용되는 전역 변수 저장

const usePlanStore = create((set) => ({
  userPlanInfo: null,
  isLoading: true,
  planRefetch: () => {},
  set: (payload: any) => set(() => payload),
  setLoading: (loading: boolean) => set({ isLoading: loading }),
  setRefetch: (refetch: any) => set({ planRefetch: refetch }), // 수정된 부분
  destroy: () => set({}, true),
}))
export default usePlanStore
