import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { MobilePage } from '../components/MyPage/MobilePage'
import { Page } from '../components/MyPage/Page'
import useGetMePlan from '../hooks/query/useGetMePlan'
import { toast } from 'react-toastify'
import { useGetMyPoint } from '../hooks/query/point/useGetMyPoint'
import { useStore } from '../store/useStore'

export const MyPage = () => {
  const { data: userPlanInfo, refetch, isLoading } = useGetMePlan()
  const { data: myPointData, refetch: getMyPointRefetch } = useGetMyPoint()
  const { signOutModal, setSignOutModal } = useStore()
  const handleWithDrawUser = () => {
    setSignOutModal(true)
  }
  const handleCopyClipBoard = async (copyText: string, successText: string) => {
    try {
      await navigator.clipboard.writeText(copyText)
      toast.success(successText, {
        position: 'bottom-center',
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } catch (e) {}
  }
  useEffect(() => {
    refetch()
  }, [myPointData, refetch])
  return isMobile ? (
    <MobilePage
      userPlanInfo={userPlanInfo}
      handleCopyClipBoard={handleCopyClipBoard}
      myPointData={myPointData}
      handleWithDrawUser={handleWithDrawUser}
    />
  ) : (
    <Page
      userPlanInfo={userPlanInfo}
      handleCopyClipBoard={handleCopyClipBoard}
      myPointData={myPointData}
      handleWithDrawUser={handleWithDrawUser}
    />
  )
}
