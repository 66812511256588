import classNames from 'classnames'
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { Control, Controller } from 'react-hook-form'
import { FormValues } from '../../../utils/type'
import { Me } from '../../../service/user'
import { VideoComponent } from '../VideoComponent'
import { PlanTag } from '../../planTag'

import { OutroFactor } from '../../../service/list'
import { OutroListModal } from '../OutroListModal'
import useGetTransition from '../../../hooks/query/useGetTransition'

interface outroDisplayProps {
  watch: (name: 'outroId', defaultValue?: number | undefined) => number
  setValue: (
    name: 'outroId',
    value: number,
    options?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
          shouldTouch: boolean
        }>
      | undefined
  ) => void
  control: Control<FormValues, any>
  userInfo: Me
  translatePlanName: (planName: string) => string
  copyOutroList: OutroFactor[] | []
  setCopyOutroList: Dispatch<SetStateAction<[] | OutroFactor[]>>
}

export const OutroDisplay = ({
  watch,
  setValue,
  control,
  userInfo,
  translatePlanName,
  copyOutroList,
  setCopyOutroList,
}: PropsWithChildren<outroDisplayProps>) => {
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([])
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const [videoPlaying, setVideoPlaying] = useState<boolean[]>(
    new Array(copyOutroList?.length).fill(false)
  )
  const [isOutroModal, setOutroModal] = useState<boolean>(false)
  const [selectedOutroIndex, setSelectedOutroIndex] = useState<number>(0)

  const outroMap = useMemo(() => {
    if (copyOutroList) {
      const map = new Map<number, (typeof copyOutroList)[0]>()
      copyOutroList.forEach((item) => {
        map.set(item.id, item) // 숫자를 그대로 사용 가능
      })
      setVideoPlaying(new Array(copyOutroList?.length).fill(false))
      return map
    }
  }, [copyOutroList])

  const handleSelectedOutro = (behavior: boolean) => {
    if (videoRef.current && behavior) {
      console.log(videoRef.current!.readyState, '선택됨')
      if (videoRef.current!.readyState >= 2) {
        try {
          videoRef.current!.muted = true
          videoRef.current!.currentTime = 0
          videoRef.current?.play().catch((error) => {
            console.warn('Playback failed:', error)
          })
        } catch (e) {
          console.log(e, 'not play video index:')
        }
      }
    } else if (videoRef.current && !behavior) {
      if (videoRef.current!.readyState >= 2) {
        try {
          videoRef.current!.currentTime = 1
          videoRef.current?.pause()
        } catch (e) {
          console.log(e, 'not play video index:')
        }
      }
    }
  }

  const handleVideoEnded = (index: number) => {
    const updatedPlayingState = [...videoPlaying]
    updatedPlayingState[index] = false
    setVideoPlaying(updatedPlayingState)
  }

  const handlePlayButtonClick = (index: number) => {
    const updatedPlayingState = videoPlaying.map((value, idx) =>
      idx === index ? true : false
    )

    setVideoPlaying(updatedPlayingState)
  }

  // 선택된 항목 가져오기
  const selectedOutroId = watch('outroId')
  const selectedItem = outroMap?.get(selectedOutroId) ?? 0

  const renderSelectedItem = () => {
    if (!selectedItem) return null

    return selectedItem.name === '없음' ? (
      <div
        className="flex md:flex-col relative w-[138px] h-[78px] justify-center outline outline-[4px] outline-main"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <button type="button" className="text-white font-bold z-50">
          효과 없음
        </button>
      </div>
    ) : (
      <div
        className={classNames(
          'flex w-[138px] relative outline outline-4 outline-main'
        )}
        onMouseEnter={!isMobile ? () => handleSelectedOutro(true) : undefined}
        onMouseLeave={!isMobile ? () => handleSelectedOutro(false) : undefined}
        onClick={isMobile ? () => handleSelectedOutro(true) : undefined}
      >
        {videoRef ? (
          <video
            ref={videoRef}
            src={selectedItem.path}
            playsInline
            width={138}
            loop
            autoPlay
            muted
            height={78}
            preload="auto"
          />
        ) : (
          <img
            src={selectedItem.imagePath}
            className="w-[138px] h-[78px]"
            loading="lazy"
            alt=""
          />
        )}
        <div className="absolute w-[138px] h-[78px] flex">
          <span className="font-bold text-white text-[14px] ml-1 mt-1">
            선택됨
          </span>
        </div>
      </div>
    )
  }

  const renderOutroItem = (outro: OutroFactor, index: number) => {
    const isSelected = watch('outroId') === outro.id

    return (
      <div
        key={index}
        className="flex md:flex-col relative"
        onMouseEnter={
          !isMobile ? () => handlePlayButtonClick(index) : undefined
        }
        onMouseLeave={!isMobile ? () => handleVideoEnded(index) : undefined}
        onClick={isMobile ? () => handlePlayButtonClick(index) : undefined}
      >
        {outro?.name !== '없음' ? (
          <VideoComponent
            videoRefs={videoRefs}
            src={outro.path}
            className={classNames({
              'outline outline-[4px] outline-main': isSelected,
            })}
            imagePath={outro.imagePath}
            index={index}
            selected={isSelected}
            videoPlaying={videoPlaying}
          />
        ) : (
          <div
            className={classNames(
              'flex  md:flex-col  relative w-[138px] h-[78px] justify-center',
              {
                ' outline outline-[4px] outline-main':
                  outro.name === '없음' && outro.id === watch('outroId'),
              }
            )}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={() => {
              setSelectedOutroIndex(index)
              setValue('outroId', outro.id)
            }}
          >
            <button
              type="button"
              className={classNames(
                'text-white font-bold z-50 w-[138px] h-[78px]'
              )}
            >
              효과 없음
            </button>
          </div>
        )}
        {index === (isMobile ? 3 : 4) ? (
          <div
            className={`absolute w-[138px] h-[78px] flex items-center justify-center $ `}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <button
              type="button"
              onClick={() => {
                setOutroModal((prev) => !prev)
              }}
              className=" text-white font-bold z-50"
            >
              {copyOutroList?.length - (isMobile ? 4 : 5)}개 더보기
            </button>
          </div>
        ) : (
          <div
            className={classNames('absolute w-[138px] h-[78px] flex', {
              'bg-opacity-50': isSelected,
            })}
            style={{
              backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.5)' : undefined,
            }}
            onClick={() => {
              setSelectedOutroIndex(index)
              setValue('outroId', outro.id)
            }}
          >
            <div className="flex items-start h-full pt-1 mt-[6px] ml-[6px]">
              <PlanTag planName={translatePlanName(outro.planName)} />
            </div>
            <Controller
              name="outroId"
              control={control}
              render={({ field }: any) => (
                <label className="relative flex w-full cursor-pointer items-end justify-end mr-1 mb-1">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isSelected}
                    disabled={!outro.usable}
                    onChange={() => {
                      setSelectedOutroIndex(index)
                      setValue('outroId', outro.id)
                    }}
                  />
                  <div
                    className={classNames(
                      'w-[20px] h-[20px] rounded-full border-2 flex items-center justify-center',
                      {
                        'bg-white border-main': isSelected,
                        'bg-white': !isSelected,
                      }
                    )}
                  >
                    {isSelected && (
                      <div className="w-3 h-3 bg-main rounded-full"></div>
                    )}
                  </div>
                </label>
              )}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <div className="likeCommentWatch  my-[40px]">
        <div className="flex items-center md:mb-[16px] gap-4 ">
          <p className="font-bold text-[18px]">
            영상 마지막 구독 & 좋아요 요청 화면
          </p>
          <p className="flex  items-center gap-1 ">
            <span
              className="font-bold text-white text-[11px] px-1 py-[2px] rounded-[4px]"
              style={{
                background: `linear-gradient(to right,#6095FF,#00CBB3)`,
              }}
            >
              UPDATE
            </span>
            {!isMobile && (
              <span className="text-[14px] text-[#5E6068]">
                아웃트로 클립을 넣어 구독자를 늘려보세요!
              </span>
            )}
          </p>
        </div>{' '}
        {isMobile && (
          <div className="mb-[16px] md:mb-0">
            <p className="text-[14px] text-[#5E6068]">
              아웃트로 클립을 넣어 구독자를 늘려보세요!
            </p>
          </div>
        )}
        {/* 선택된 좋구알 비디오 화면 */}
        <div className="selected_video mb-4">{renderSelectedItem()}</div>
        {/* 좋구알 비디오 리스트  */}
        <div
          className={classNames('', {
            'flex md:flex-row flex-col gap-[24px] md:gap-1 justify-between bg-[#EEF1F7] md:h-[118px]  px-[10px] py-5 rounded-[10px]':
              !isMobile,
            'grid grid-cols-2 gap-x-[27px] gap-y-5 bg-[#EEF1F7]  px-[10px]  py-5 rounded-[10px] ':
              isMobile,
          })}
        >
          {copyOutroList?.map((outro, index) => {
            if (index <= (isMobile ? 3 : 4))
              return <>{renderOutroItem(outro, index)}</>

            return <> </>
          })}
        </div>
      </div>
      <OutroListModal
        isOutroModal={isOutroModal}
        setOutroModal={setOutroModal}
        handlePlayButtonClick={handlePlayButtonClick}
        handleVideoEnded={handleVideoEnded}
        videoRefs={videoRefs}
        videoPlaying={videoPlaying}
        userInfo={userInfo}
        Controller={Controller}
        watch={watch}
        translatePlanName={translatePlanName}
        control={control}
        setValue={setValue}
        selectedOutroIndex={selectedOutroIndex}
        setSelectedOutroIndex={setSelectedOutroIndex}
        copyOutroList={copyOutroList}
        setCopyOutroList={setCopyOutroList}
      />
    </>
  )
}
