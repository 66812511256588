import { useQuery } from '@tanstack/react-query'

import { getBillingMethods } from '../../../service/brandpay'
import { getChallenge } from '../../../service/challenge'

function useGetBillingMethods() {
  return useQuery({
    queryKey: ['get-billing-methods'],
    queryFn: () => getBillingMethods(),
    staleTime: 0,
  })
}

export default useGetBillingMethods
