import React from 'react'
import { isMobile } from 'react-device-detect'
import { FreeButton } from '../atom/FreeButton'
import usePrevStore from '../../store/usePrevStore'

export const Banner = (props: any) => {
  const { paymentFreePlan, SetFreePlanModal, prevpath } = props
  const prevSet = usePrevStore((state: any) => state.set)

  const handlePrevPath = () => {
    if (prevpath) {
      prevSet({ prevUrl: prevpath })
    }
  }

  return isMobile ? (
    <>
      <div className="w-[375px] h-auto bg-href flex p-4 gap-2">
        <div className="w-[199px] flex flex-col">
          <p className="text-white font-[Tenada] font-[800] text-[20px]">
            상품 영상 만들기 무료 체험
          </p>
          <p className="text-[14px] text-[#C4DFFF]">
            {' '}
            <span className="font-[800] text-white">
              프리미엄 플랜의 모든 기능!
            </span>
            <br />
            영상 파일 다운로드, 유튜브 자동 업<br />
            로드까지 무료 체험 하세요.
          </p>
        </div>
        <div className=" w-[136px] flex flex-col  gap-1  justify-center items-end">
          <button
            className="w-[136px] h-[38px] border-[2px] border-[white] rounded-full text-white font-bold text-[14px] "
            onClick={() => {
              SetFreePlanModal((prev: any) => !prev)
            }}
          >
            체험 내용 상세
          </button>

          <button
            className="w-[136px] h-[38px] rounded-full text-href       font-bold text-[14px] bg-white"
            onClick={() => {
              handlePrevPath()
              paymentFreePlan()
            }}
          >
            지금 바로 체험
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="md:w-[1000px] md:h-[208px] p-5 md:px-[32px] md:py-[16px] flex flex-col md:flex-row items-center justify-between border-[2px] border-href rounded-[20px]">
        <div className="flex flex-col items-center">
          <p className="text-href font-bold md:text-[24px]">
            프리미엄 플랜 기능
          </p>
          <p className="font-[800] md:text-[32px] text-[20px]">무료 체험</p>
        </div>
        <div className="md:text-[18px] text-[14px] font-bold flex flex-col gap-[16px] mt-[22px] md:mt-0">
          <p className="flex items-center gap-2">
            {' '}
            <img
              src="assets/img/check_circle_blue.png"
              alt="check"
              width={24}
              height={24}
            />
            최고사양 플랜의 모든 기능을 써보고 결정하세요!
          </p>
          <p className="flex items-center gap-2">
            {' '}
            <img
              src="assets/img/check_circle_blue.png"
              alt="check"
              width={24}
              height={24}
            />
            영상 파일 다운로드는 물론, 유튜브 자동 업로드까지!
          </p>
        </div>
        <div className="flex flex-col gap-[8px] font-bold text-[18px] mt-[22px] md:mt-0">
          <button
            className="w-[264px] h-[60px]  border-href border-[2px] rounded-[40px] text-href"
            onClick={() => {
              SetFreePlanModal((prev: any) => !prev)
            }}
          >
            체험내용 상세보기
          </button>
          <FreeButton
            className="w-[264px] h-[60px] bg-href  rounded-[40px] text-white"
            onClick={() => {
              handlePrevPath()
              paymentFreePlan()
            }}
          >
            지금 바로 체험하기
          </FreeButton>
        </div>
      </div>
    </>
  )
}

export const FreePlanBanner = (props: any) => {
  const { SetFreePlanModal, paymentFreePlan } = props
  return isMobile ? (
    <>
      {' '}
      <div className="w-[375px] h-auto bg-href flex p-4 gap-2">
        <div className="w-[199px] flex flex-col">
          <p className="text-white font-[Tenada] font-[800] text-[20px]">
            상품 영상 만들기 무료 체험
          </p>
          <p className="text-[14px] text-[#C4DFFF]">
            {' '}
            <span className="font-[800] text-white">
              프리미엄 플랜의 모든 기능!
            </span>
            <br />
            영상 파일 다운로드, 유튜브 자동 업<br />
            로드까지 무료 체험 하세요.
          </p>
        </div>
        <div className=" w-[136px] flex flex-col  gap-1  justify-center items-end">
          <button
            className="w-[136px] h-[38px] border-[2px] border-[white] rounded-full text-white font-bold text-[14px] "
            onClick={() => {
              SetFreePlanModal((prev: any) => !prev)
            }}
          >
            체험 내용 상세
          </button>

          <button
            className="w-[136px] h-[38px] rounded-full text-href       font-bold text-[14px] bg-white"
            onClick={paymentFreePlan}
          >
            지금 바로 체험
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="flex flex-col w-full gap-[8px] items-center  ">
        <div className="md:w-[816px] md:h-[104px] p-5 md:px-[32px] md:py-[16px] flex flex-col md:flex-row items-center justify-between border-[2px] border-href rounded-[20px]">
          <div className="flex flex-col items-center">
            <p className="text-href font-bold ">프리미엄 플랜 기능</p>
            <p className="font-[800] text-[24px] ">무료 체험</p>
          </div>
          <div className="md:text-base text-[14px] font-bold flex flex-col gap-[16px] mt-[22px] md:mt-0">
            <p className="flex items-center gap-2">
              {' '}
              <img
                src="assets/img/check_circle_blue.png"
                alt="check"
                width={24}
                height={24}
              />
              최고사양 플랜의 모든 기능 사용해보기!
            </p>
            <p className="flex items-center gap-2">
              {' '}
              <img
                src="assets/img/check_circle_blue.png"
                alt="check"
                width={24}
                height={24}
              />
              파일 다운로드, 유튜브 자동 업로드까지!
            </p>
          </div>
          <div className="flex flex-col gap-[8px] font-bold  mt-[22px] md:mt-0">
            <FreeButton
              className="w-[166px] h-[48px] bg-href  rounded-[40px] text-white"
              onClick={paymentFreePlan}
            >
              지금 바로 체험하기
            </FreeButton>
          </div>
        </div>{' '}
      </div>
    </>
  )
}
