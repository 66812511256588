import React, { useState } from 'react'

export const ThumbnailImgComponent = (props: any) => {
  const { thumbnail, index } = props
  const [isLoading, setIsLoading] = useState(true) // 전체 로딩 상태 (스켈레톤 여부)
  return (
    <div className="w-[138px] h-[78px] relative">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-300 overflow-hidden">
          <div className="h-full w-full bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 animate-skeleton"></div>
        </div>
      )}
      <img
        src={thumbnail.path}
        width={138}
        height={78}
        alt="img"
        className={` relative ${index ? 'outline outline-4 outline-main' : ''}`}
        onLoad={() => setIsLoading(false)}
        onLoadedData={() => setIsLoading(false)}
      />
    </div>
  )
}
