import React, { PropsWithChildren } from 'react'
import { Control, Controller } from 'react-hook-form'
import { FormValues } from '../../../utils/type'
import { Me } from '../../../service/user'
import { CircularCheckbox } from '../../CircularCheckbox'

interface PriceDisplayProps {
  watch: (name: 'includePrice', defaultValue?: boolean | undefined) => boolean
  setValue: (
    name: 'includePrice',
    value: boolean,
    options?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
          shouldTouch: boolean
        }>
      | undefined
  ) => void
  control: Control<FormValues, any>
  userInfo: Me
}
export const PriceDisplay = ({
  watch,
  setValue,
  control,
  userInfo,
}: PropsWithChildren<PriceDisplayProps>) => {
  return (
    <div className="price  my-[40px]">
      <div className="flex flex-col md:mb-[16px] gap-4 ">
        <p className="font-bold text-[18px]">영상 속 상품 가격 정보</p>
        <div className="grid grid-cols-2 ">
          {[
            { name: '넣기', value: true },
            { name: '빼기', value: false },
          ].map((el, index) => {
            return (
              <div>
                <label
                  onClick={() => {
                    setValue('includePrice', el.value)
                  }}
                >
                  <Controller
                    render={({ field }) => (
                      <CircularCheckbox
                        {...field}
                        id={index}
                        checked={watch('includePrice') === el.value}
                        label={el.name}
                        onChange={() => {
                          setValue('includePrice', el.value)
                        }}
                        disabled={
                          !userInfo || userInfo?.userType === 'normalUser'
                        }
                      />
                    )}
                    name="includePrice"
                    control={control}
                  />
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
