import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useGetPaymentOrderRequests from '../../hooks/query/useGetPaymentOrderRequests'
import { UserType } from '../../utils/common'
import {
  AffiliateBenefit,
  CouponBenefit,
  DefaultBenefit,
  InviteBenefit,
  PaymentOrderRequest,
} from '../../service/payment'
import useGetPlansPrice from '../../hooks/query/plans/useGetPlansPrice'
import { PaymentModal } from '../payment/PaymentModal'
import { subscriptionType } from '../../service/plans'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

export const PromotionCircle = (props: any) => {
  const { userInfo, classNameProps } = props

  const navigate = useNavigate()
  const { mutate: getPaymentOrderRequestsMutate } = useGetPaymentOrderRequests()

  const [monthPrice, setMonthPrice] = useState(0)
  const [isUpgrade, setUpgrade] = useState(true)
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [isExist, setExist] = useState<boolean>(false)
  const [planId, setPlanId] = useState(0)
  const [type, setType] = useState('annual')
  const [upgradePlanType, setUpgradePlanType] = useState('')
  const [upgradePlanName, setUpgradePlanName] = useState('')
  const [upgradeDiscount, setUpGradeDiscount] = useState(0)
  const [text, setText] = useState('')
  const [couponBenefit, setCouponBenefit] = useState<null | CouponBenefit>(null)
  const [paymentOrderRequest, setPaymentOrderRequest] =
    useState<PaymentOrderRequest>({
      orderId: '',
      productName: '',
      subscriptionType: '',
      price: 0,
      vat: 0,
      id: 0,
    })

  const {
    isLoading: priceIsLoading,
    data: priceData,
    refetch: priceRefetch,
  } = useGetPlansPrice(type)

  const [affiliateBenefit, setAffiliateBenefit] =
    useState<null | AffiliateBenefit>(null)
  const [inviteeBenefit, setInviteBenefit] = useState<null | InviteBenefit>(
    null
  )
  const [defaultBenefit, setDefaultBenefit] = useState<null | DefaultBenefit>(
    null
  )

  const selectBg = (planName: string) => {
    if (planName === 'LIGHT' || planName === '라이트') {
      return 'rgba(96, 184, 251, 1),rgba(11, 123, 209, 1),rgba(156, 224, 255, 1)'
    } else if (planName === 'PREMIUM' || planName === '프리미엄') {
      return 'rgba(0, 128, 130, 1),rgba(0, 94, 94, 1),rgba(29, 197, 194, 1)'
    } else if (planName === 'PRO' || planName === '프로') {
      return 'rgba(96, 16, 157, 1),rgba(14, 0, 94, 1),rgba(190, 73, 198, 1)'
    } else if (planName === 'FREE' || planName === '무료') {
      return 'rgba(128, 127, 123, 1),rgba(60, 68, 68, 1),rgba(165, 179, 177, 1)'
    }
    return 'rgba(0, 128, 130, 1),rgba(0, 94, 94, 1),rgba(29, 197, 194, 1)'
  }

  const handlePromotion = () => {
    if (
      userInfo?.userType === UserType.라이트 &&
      userInfo?.subscriptions?.subscriptionType === subscriptionType.MONTHLY
    ) {
      setText(`${userInfo.subscriptions.planName} 1년 40% 할인받기`)
      setType(subscriptionType.ANNUAL)
      setUpgradePlanName(userInfo.subscriptions.planName)
      setUpGradeDiscount(0)
      setExist(true)

      return
    } else if (
      (userInfo?.userType === UserType.프로 ||
        userInfo?.userType === UserType.프리미엄) &&
      userInfo?.subscriptions?.subscriptionType === subscriptionType.MONTHLY
    ) {
      setText(`${userInfo.subscriptions.planName} 1년 40% 할인받기`)
      setType(subscriptionType.ANNUAL)
      setUpgradePlanName(userInfo.subscriptions.planName)
      setUpGradeDiscount(0)
      setExist(true)
      return
    } else if (
      userInfo?.userType === UserType.체험 ||
      userInfo?.userType === UserType.USER
    ) {
      setText(`프리미엄 1년 40% 할인받기`)
      setType(subscriptionType.ANNUAL)
      setUpgradePlanName('프리미엄')
      setUpGradeDiscount(0)
      setExist(true)
      setUpgrade(false)
      return
    } else {
      setExist(false)
      return
    }
  }

  const getPaymentOrderRequests = () => {
    const upgradePriceInfoArr = priceData?.prices?.filter(
      (el) => el.name === upgradePlanName
    )

    if (upgradePriceInfoArr) {
      const planId = upgradePriceInfoArr[0]?.id
      setPlanId(planId)
      setMonthPrice(Number(userInfo?.subscriptions?.price))
      const planPriceId = upgradePriceInfoArr[0]?.planPrice[0]?.id
      if (planId) {
        getPaymentOrderRequestsMutate(
          { planId, planPriceId },
          {
            onSuccess: (data) => {
              setPaymentOrderRequest(data.orderRequest)
              if (data.affiliateBenefit) {
                setAffiliateBenefit(data.affiliateBenefit)
              }
              if (data?.couponBenefit) {
                setCouponBenefit(data.couponBenefit)
              }
              if (data?.inviteBenefit) {
                setInviteBenefit(data.inviteBenefit)
              }
              if (data?.defaultBenefit && type === 'annual') {
                setDefaultBenefit(data.defaultBenefit)
              }
              setPaymentModal(true)
            },
            onError: (e) => {
              console.log(e)
            },
          }
        )
      }
    }
  }

  useEffect(() => {
    handlePromotion()
  }, [paymentModal, priceData, userInfo])

  return (
    <>
      {isExist && (
        <button
          className={classNames('text-[14px]', {
            'w-full h-[34px]  rounded-[10px] text-white font-bold':
              !classNameProps,
            'w-full h-[46px]  rounded-full text-white font-bold':
              classNameProps,
          })}
          style={{
            background: `linear-gradient(to right, ${selectBg(
              userInfo?.subscriptions?.planName
            )})`,
          }}
          onClick={() => {
            getPaymentOrderRequests()
          }}
        >
          {' '}
          {text ?? ''}
        </button>
      )}
      {paymentModal && (
        <PaymentModal
          setPaymentModal={setPaymentModal}
          paymentOrderRequest={paymentOrderRequest}
          affiliateBenefit={affiliateBenefit}
          couponBenefit={couponBenefit}
          setCouponBenefitData={setCouponBenefit}
          setAffiliateBenefitData={setAffiliateBenefit}
          affiliatePlan={userInfo?.affiliate?.plan}
          inviteBenefit={inviteeBenefit}
          userInfo={userInfo?.userInfo}
          planId={planId}
          type={'annual'}
          upgrade={isUpgrade}
          monthPrice={monthPrice}
          defaultBenefit={defaultBenefit}
          setDefaultBenefitData={setDefaultBenefit}
        />
      )}
    </>
  )
}
