import React from 'react'
import { isMobile } from 'react-device-detect'

export const GuideToUse = () => {
  return isMobile ? (
    <>
      {' '}
      <div className="w-[335px] h-auto p-[40px]  bg-[#E7F9F7] flex flex-col items-center gap-[10px] border rounded-[20px] mt-[40px] px-5 py-8">
        <div className="flex flex-col items-center gap-[4px]">
          <p className="text-[#2E343D] font-bold leading-[28.8px] text-center text-[18px] md:text-[24px]">
            💁🏻 패스트컷 사용 가이드
          </p>
          <p className="text-main text-[10px] md:text-[14px] font-bold">
            패스트컷을 잘 사용하시려면 가이드를 한 번씩 읽어봐주세요!
          </p>
        </div>
        <div className=" w-full flex flex-col gap-6 items-start">
          <p className="flex gap-2 items-center">
            <img
              src="/assets/img/coupang_icon.png"
              alt="youtube icon"
              className="w-[24px] h-[24px]"
            />
            <a
              href="https://blog.fastseller.shop/coupangpartnersguide/"
              target="_blank"
              rel="noreferrer"
              className="text-base"
            >
              쿠팡 파트너스 가이드
            </a>
          </p>
          <p className="flex gap-2 items-center">
            <img
              src="/assets/img/ali_icon.png"
              alt="youtube icon"
              className="w-[24px] h-[24px]"
            />
            <a
              href="https://blog.fastseller.shop/aliexpressaffiliatteguide/"
              target="_blank"
              rel="noreferrer"
              className="text-base"
            >
              알리익스프레스 어필리에이트 가이드
            </a>
          </p>
          <p className="flex gap-2 items-center">
            <img
              src="/assets/img/icon_shorts.png"
              alt="youtube icon"
              className="w-[24px] h-[24px]"
            />
            <a
              href="https://blog.fastseller.shop/youtubeshortsupload/"
              target="_blank"
              rel="noreferrer"
              className="text-base"
            >
              컨텐츠 영상 업로드 가이드
            </a>
          </p>
          <p className="flex gap-2 items-center">
            <img
              src="/assets/img/icon_logo.png"
              alt="youtube icon"
              className="w-[24px] h-[24px]"
            />
            <a
              href="https://blog.fastseller.shop/"
              target="_blank"
              rel="noreferrer"
              className="text-base"
            >
              패스트컷 사용가이드
            </a>
          </p>
          <p className="flex gap-2 items-center">
            <img
              src="/assets/img/icon_logo.png"
              alt="youtube icon"
              className="w-[24px] h-[24px]"
            />
            <a
              href="https://blog.fastseller.shop/youtubeuploadguide/"
              target="_blank"
              rel="noreferrer"
              className="text-base"
            >
              라이트플랜 영상 업로드 방법 가이드
            </a>
          </p>
          <p className="flex gap-2 items-center">
            <img
              src="/assets/img/icon_logo.png"
              alt="youtube icon"
              className="w-[24px] h-[24px]"
            />
            <a
              href="https://blog.fastseller.shop/youtubeuploadguide/"
              target="_blank"
              rel="noreferrer"
              className="text-base"
            >
              프리미엄 플랜 영상 업로드 방법 가이드
            </a>
          </p>
          <p className="flex gap-2 items-center">
            <img
              src="/assets/img/icon_youtube.png"
              alt="youtube icon"
              className="w-[24px] h-[24px]"
            />
            <a
              href="https://blog.fastseller.shop/fastcutai_youtube_upload_guide/"
              target="_blank"
              rel="noreferrer"
              className="text-base"
            >
              유튜브 채널 권한 설정 가이드
            </a>
          </p>
          <p className="flex gap-2 items-center">
            <img
              src="/assets/img/icon_youtube.png"
              alt="youtube icon"
              className="w-[24px] h-[24px]"
            />
            <a
              href="https://blog.fastseller.shop/youtubeadvancedverification/"
              target="_blank"
              rel="noreferrer"
              className="text-base"
            >
              유튜브 고급기능 신청 가이드
            </a>
          </p>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="w-full h-auto p-[40px]  bg-[#E7F9F7] flex flex-col items-center gap-[10px] border rounded-[20px] mt-[40px]">
        <div className="flex flex-col items-center gap-[4px]">
          <p className="text-[#2E343D] font-bold leading-[28.8px] text-center text-[18px] md:text-[24px]">
            💁🏻 패스트컷 사용 가이드
          </p>
          <p className="text-main text-[10px] md:text-[14px] font-bold">
            패스트컷을 잘 사용하시려면 가이드를 한 번씩 읽어봐주세요!
          </p>
        </div>
        <div className="w-[343px] md:w-[530px] flex justify-between">
          <div className="flex flex-col gap-[16px]">
            <p className="flex gap-2">
              <img
                src="/assets/img/coupang_icon.png"
                alt="youtube icon"
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
              />
              <a
                href="https://blog.fastseller.shop/coupangpartnersguide/"
                target="_blank"
                rel="noreferrer"
                className="text-[10px] md:text-base"
              >
                쿠팡 파트너스 가이드
              </a>
            </p>
            <p className="flex gap-2">
              <img
                src="/assets/img/ali_icon.png"
                alt="youtube icon"
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
              />
              <a
                href="https://blog.fastseller.shop/aliexpressaffiliatteguide/"
                target="_blank"
                rel="noreferrer"
                className="text-[10px] md:text-base"
              >
                알리익스프레스 어필리에이트 가이드
              </a>
            </p>
            <p className="flex gap-2">
              <img
                src="/assets/img/icon_shorts.png"
                alt="youtube icon"
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
              />
              <a
                href="https://blog.fastseller.shop/youtubeshortsupload/"
                target="_blank"
                rel="noreferrer"
                className="text-[10px] md:text-base"
              >
                컨텐츠 영상 업로드 가이드
              </a>
            </p>
            <p className="flex gap-2">
              <img
                src="/assets/img/icon_logo.png"
                alt="youtube icon"
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
              />
              <a
                href="https://blog.fastseller.shop/"
                target="_blank"
                rel="noreferrer"
                className="text-[10px] md:text-base"
              >
                패스트컷 사용가이드
              </a>
            </p>
            <p className="flex gap-2">
              <img
                src="/assets/img/icon_logo.png"
                alt="youtube icon"
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
              />
              <a
                href="https://blog.fastseller.shop/youtubeuploadguide/"
                target="_blank"
                rel="noreferrer"
                className="text-[10px] md:text-base"
              >
                라이트플랜 영상 업로드 방법 가이드
              </a>
            </p>
            <p className="flex gap-2">
              <img
                src="/assets/img/icon_logo.png"
                alt="youtube icon"
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
              />
              <a
                href="https://blog.fastseller.shop/youtubeuploadguide/"
                target="_blank"
                rel="noreferrer"
                className="text-[10px] md:text-base"
              >
                프리미엄 플랜 영상 업로드 방법 가이드
              </a>
            </p>
          </div>
          <div className="flex flex-col gap-[16px]">
            <p className="flex gap-2">
              <img
                src="/assets/img/icon_youtube.png"
                alt="youtube icon"
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
              />
              <a
                href="https://blog.fastseller.shop/fastcutai_youtube_upload_guide/"
                target="_blank"
                rel="noreferrer"
                className="text-[10px] md:text-base"
              >
                유튜브 채널 권한 설정 가이드
              </a>
            </p>
            <p className="flex gap-2">
              <img
                src="/assets/img/icon_youtube.png"
                alt="youtube icon"
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
              />
              <a
                href="https://blog.fastseller.shop/youtubeadvancedverification/"
                target="_blank"
                rel="noreferrer"
                className="text-[10px] md:text-base"
              >
                유튜브 고급기능 신청 가이드
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
