import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'
import { SubtitleType } from '../../service/list'
interface Props {
  subtitleTooltip: boolean
  setSubtitleTooltip: Dispatch<SetStateAction<boolean>>
  subTitleList: SubtitleType[] | undefined
}

const SubtitleToolltip = ({
  children,
  subtitleTooltip,
  setSubtitleTooltip,
  subTitleList,
}: PropsWithChildren<Props>) => {
  const subtitle = subTitleList?.filter((el) => el.cashRate === 1)[0]
  return (
    <>
      {' '}
      <img
        src="assets/img/icon_tooltip.png"
        alt="툴팁"
        className=" w-[20px] h-[20px]"
        onMouseEnter={() => {
          setSubtitleTooltip(true)
        }}
      />
      {subtitleTooltip && (
        <div className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[300px] md:w-[366px] h-auto absolute border-[3px] border-main  rounded-[20px] -bottom-20 right-14 z-50">
          <p className="font-bold md:text-[24px] text-base flex md:items-center justify-between">
            자막은 어떻게 들어가나요?
            <button
              onClick={() => {
                setSubtitleTooltip(false)
              }}
              className="flex flex-col h-full"
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className=" md:leading-[19.2px] md:text-base  leading-[19.2px] text-[10px]">
            아래 컨텐츠 영상 화면 예시와 같이{' '}
            <span className="text-main font-bold">
              영상의 하단에 나레이션과 동일한 내용의 자막이 들어가며
            </span>
            , 자막의 위치와 서체, 사이즈는 모든 타입 동일합니다.
          </p>

          <p className="flex flex-col">
            <p className="text-[14px] text-error">할인 이벤트중</p>
            <div className="flex gap-2 items-center">
              <img
                src="assets/img/cash.png"
                alt="point"
                width={11}
                height={13}
              />
              <s className="text-[#5E6068]">{`${subtitle?.price}`}캐시</s>
              <span className="text-main">
                {`${subtitle?.originPrice}`}캐시
              </span>
            </div>
          </p>
          <div className="flex justify-center">
            <img
              src="assets/img/subtitle_example.png"
              alt="example"
              width={240}
              height={424}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default SubtitleToolltip
