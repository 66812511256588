import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Button } from '../atom/Button'
import { Modal } from '../layout/Modal'

import {
  Control,
  Controller,
  useForm,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import {
  getLocalStorage,
  LOCAL_THUMBNAIL_INFO,
  saveLocalStorage,
} from '../../utils/common'
import { Input } from '../atom/Input'
import classNames from 'classnames'
import { ThumbnailOptionType } from '../../dummy/data'
import { ThumbnailInfo } from './Thumbnail'
import { FormValues } from '../../utils/type'
interface MobileThumbnailProps {
  isMobileTModal: boolean
  setMobileTModal: Dispatch<SetStateAction<boolean>>
  userInfo: any
  thumbnailId: number
  thumbnailType: ThumbnailOptionType
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues>
}

export const MobileThumbnail = ({
  children,
  isMobileTModal,
  setMobileTModal,
  userInfo,
  thumbnailType,
  thumbnailId,
  watch,
  setValue,
  control,
}: PropsWithChildren<MobileThumbnailProps>) => {
  const localT = getLocalStorage(LOCAL_THUMBNAIL_INFO)
  //채널이름 체크박스 state
  const [isCheck, setCheck] = useState<boolean>(false)
  const [isTitleCheck, setTitleCheck] = useState<boolean>(false)
  const [essentialCheck, setEssentialCheck] = useState<boolean>(false)
  const [emptyChannelName, setEmptyChannelName] =
    useState<string>('내 채널 이름이 들어가요')

  const thumbnailOnSubmit = async (data: any) => {
    console.log(isCheck)
    saveLocalStorage(LOCAL_THUMBNAIL_INFO, {
      [thumbnailType.name]: data,
      isCheck,
      isTitleCheck,
    })
    setValue('thumbnailInfo', data)
    setMobileTModal(false)
  }

  const handleTitleCheckBox = (flag: boolean) => {
    if (flag) {
      setValue('thumbnailInfo.title1', '')
      setValue('thumbnailInfo.title2', ' ')
      setValue('thumbnailInfo.title3', ' ')
    } else {
      setValue('thumbnailInfo.title1', '')
      setValue('thumbnailInfo.title2', '')
      setValue('thumbnailInfo.title3', '')
    }
  }
  const THUMBNAIL_PATTERN = /^[^\uD800-\uDFFF]*$/
  const CHANNEL_NAME_PATTERN = /^[^\uD800-\uDFFF&]*$/
  useEffect(() => {
    if (localT && localT[thumbnailType?.name]) {
      const tInfo = localT[thumbnailType?.name]
      const titleCheckByStorage = localT['isTitleCheck']
      const channelNameCheckByStorage = localT['isCheck']

      setTitleCheck(titleCheckByStorage)
      setCheck(channelNameCheckByStorage)
      if (!tInfo?.channelName) {
        setCheck(true)
        setEmptyChannelName('')
      }
      setValue('thumbnailInfo.channelName', tInfo?.channelName)
      setValue('thumbnailInfo.title1', tInfo?.title1)
      setValue('thumbnailInfo.title2', tInfo?.title2)
      setValue('thumbnailInfo.title3', tInfo?.title3)
    }

    if (userInfo) {
      if (userInfo?.userInfo?.youtubeAccount) {
        setValue(
          'thumbnailInfo.channelName',
          userInfo?.userInfo?.youtubeAccount.youtubeChannelName
        )
      }
    }
  }, [isMobileTModal])
  return (
    <div className="fixed bg-[#FFFFFF] border-t w-[375px] h-[100vh] left-50 top-0 z-[999] overflow-y-auto pb-[200px]">
      <div
        className="flex justify-end mt-[20px] mr-[20px]"
        onClick={() => {
          setMobileTModal(false)
        }}
      >
        <button>
          <img src="assets/img/Icon_x.png" alt="x" width={40} height={40} />
        </button>
      </div>

      <div className="flex flex-col items-center mt-[16px">
        <p className="font-bold text-[24px]">
          썸네일 {thumbnailType.name.split('_')[1]}
        </p>
        <p className="text-[14px] text-main">
          {`내용이 ${thumbnailType.titleList.length}줄 들어가는 썸네일 스타일을 선택하셨어요`}
        </p>
        <p className="text-[14px] text-center text-href">
          적용된 배경 이미지는 예시입니다. 실제 썸네일에는 선택하신 <br />
          상품페이지의 첫 번째 상품 이미지가 적용됩니다.
        </p>
        <div className="flex justify-center mt-[16px]">
          <img
            src={thumbnailType.img_url}
            alt="thumbnail"
            className="w-[138px] h-[78px]"
          />
        </div>
      </div>

      <div className="w-[375px] mt-[32px]  ">
        <div className="flex flex-col items-center gap-[4px] mt-[32px]">
          <p className="font-bold text-[24px]">썸네일 내용 입력</p>
          <p className="text-main text-[14px]">매력적인 내용을 정해주세요!</p>
          <div className="flex items-center gap-[6px] mt-[8px] text-[#8F929B] w-full px-4">
            <input
              type="checkbox"
              className="w-[20px] h-[20px] border border-[#8F929B] "
              checked={isTitleCheck}
              onClick={() => {
                setTitleCheck(!isTitleCheck)
                handleTitleCheckBox(!isTitleCheck)
              }}
            />
            <p style={{ fontWeight: 500 }} className="text-error">
              썸네일에 텍스트 넣지 않기
            </p>
          </div>
          {thumbnailType.titleList.map((el, index) => {
            return (
              <>
                <div className="mt-[16px]">
                  <Controller
                    key={el.title.titleName}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          error={
                            field.value.length > el.title.maxLength ||
                            !THUMBNAIL_PATTERN.test(field.value)
                              ? true
                              : false
                          }
                          disabled={isTitleCheck}
                          placeholder={el.title.placeholder}
                          maxLength={el.title.maxLength}
                        />
                        <div
                          className={`flex gap-[4px] mt-[8px] ${
                            (field.value.length > el.title.maxLength ||
                              !THUMBNAIL_PATTERN.test(field.value)) &&
                            'text-error'
                          }`}
                        >
                          <span
                            className={classNames('', {
                              'text-[#D4D9DF]': isTitleCheck,
                            })}
                          >
                            {field.value.length}/{el.title.maxLength}
                          </span>
                          <span
                            className={classNames('', {
                              'text-[#D4D9DF]': isTitleCheck,
                            })}
                          >
                            {' '}
                            {`이모지는 허용되지 않습니다.`}
                          </span>
                        </div>
                      </>
                    )}
                    name={`thumbnailInfo.${el.title.titleName}`}
                    control={control}
                    rules={{
                      required: {
                        value:
                          watch('thumbnailInfo.title1') ||
                          watch('thumbnailInfo.title2') ||
                          watch('thumbnailInfo.title3')
                            ? false
                            : true,
                        message: '썸네일 내용은 필수입니다!',
                      },
                      maxLength: {
                        value: el.title.maxLength,
                        message: `썸네일 내용의 길이가 ${el.title.maxLength}자를 넘습니다.`,
                      },
                      validate:
                        watch(`thumbnailInfo.${el.title.titleName}`) !== ''
                          ? (value) => {
                              return (
                                THUMBNAIL_PATTERN.test(value) ||
                                '이모지는 허용되지 않습니다.'
                              )
                            }
                          : undefined,
                    }}
                  />
                </div>
              </>
            )
          })}
        </div>
        <div className="flex flex-col items-center gap-[4px] mt-10 ">
          <p className="font-bold text-[24px]">채널 이름 입력</p>
          <p className="text-main text-[14px]">
            썸네일 왼쪽 상단에 들어갈 유튜브 채널 이름을 입력해주세요
          </p>
          <div className="mt-[16px]">
            <Controller
              key={`channelName`}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    error={
                      field.value.length > 20 ||
                      !CHANNEL_NAME_PATTERN.test(field.value)
                        ? true
                        : false
                    }
                    isCheck={isCheck}
                    placeholder="운영하시는 유튜브 채널 이름을 입력해주세요 (20자 이내)"
                    maxLength={20}
                    disabled={isCheck}
                  />
                  <div
                    className={`flex gap-[4px] mt-[8px] ${
                      (field.value.length > 20 ||
                        !CHANNEL_NAME_PATTERN.test(field.value)) &&
                      'text-error'
                    }`}
                  >
                    <span>{field.value.length}/20</span>
                    <span>{`이모지와 &는 허용되지 않습니다.`}</span>
                  </div>
                  {/* 채널이름 미설정 체크박스  */}
                  {/* <div className="flex items-center gap-[6px] mt-[8px] text-[#8F929B]">
                    <input
                      type="checkbox"
                      className="w-[20px] h-[20px] border border-[#8F929B]"
                      checked={isCheck}
                      onClick={() => {
                        setValue('thumbnailInfo.channelName', '')
                        setEmptyChannelName('')
                        setCheck((prev) => !prev)
                      }}
                    />
                    <p style={{ fontWeight: 500 }}>채널 이름 넣지 않기</p>
                  </div> */}
                </>
              )}
              name={`thumbnailInfo.channelName`}
              control={control}
              rules={{
                required: {
                  value: !isCheck,
                  message: '채널이름은 필수입니다!',
                },
                maxLength: {
                  value: 20,
                  message: '채널 이름의 길이가 20자를 넘습니다.',
                },
                validate:
                  watch(`thumbnailInfo.channelName`) !== ''
                    ? (value) => {
                        return (
                          CHANNEL_NAME_PATTERN.test(value) ||
                          '이모지와 &는 허용되지 않습니다.'
                        )
                      }
                    : undefined,
              }}
            />
          </div>
        </div>
        <div className="mt-[32px] flex justify-center">
          <Button
            className="bg-main flex items-center justify-center w-[335px] h-[60px] rounded-[40px]"
            onClick={() => {
              thumbnailOnSubmit(watch('thumbnailInfo'))
            }}
          >
            <span className="text-[#FFFFFF] font-bold">저장</span>
          </Button>
        </div>
      </div>

      {essentialCheck && (
        <Modal isModal={essentialCheck}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">
                🙌 필수 정보를 입력해주세요!
              </p>
              <p className="text-[14px]">
                내용이 입력되지 않아 저장할 수 없어요 .
              </p>
            </div>
          </div>
          <div className="w-[400px] bg-main h-[60px] border-b border-main flex   rounded-b-[20px] justify-center items-center">
            <Button
              className="text-white w-[400px] h-full  rounded-b-[20px]"
              onClick={() => {
                setEssentialCheck(false)
              }}
            >
              확인
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}
