import { useMutation } from '@tanstack/react-query'
import {
  brandPayTransaction,
  getBillingCustomerKey,
  putBillingMethods,
} from '../../../service/brandpay'

function usePostBrandPayTransaction() {
  return useMutation({
    mutationKey: ['post-brand-pay-transaction'],
    mutationFn: brandPayTransaction,
  })
}

export default usePostBrandPayTransaction
