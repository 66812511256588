import React, { useState } from 'react'
import { My } from '../components/My'
import { isMobile } from 'react-device-detect'
import { Modal } from '../components/layout/Modal'
import useGetUserSubscriptionInfo from '../hooks/query/useGetUserSubscriptionInfo'
import usePutBillingDisable from '../hooks/query/brandpay/usePutBillingDisable'
import usePutBillingEnable from '../hooks/query/brandpay/usePutBillingEnable'
import { useNavigate } from 'react-router-dom'
import usePlanStore from '../store/usePlanStore'
import useUserStore from '../store/useUserStore'

export const CancelSubscription = () => {
  const [isModal, setModal] = useState(false)
  const refetch = useUserStore((state: any) => state.refetch)
  const planRefetch = usePlanStore((state: any) => state.planRefetch)

  const { data: userSubscriptionInfo } = useGetUserSubscriptionInfo()
  const navigate = useNavigate()
  const { mutate: disableMutate, isPending: disablePending } =
    usePutBillingDisable()

  const handleDisableSubscription = () => {
    disableMutate('', {
      onSuccess: () => {
        console.log(refetch, 'sdf', planRefetch)
        refetch()
        planRefetch()
        navigate('/cancel-subscription-confirm')
      },
      onError: () => {},
    })
  }

  return (
    <div className=" md:w-[816px] w-[375px] min-h-[100vh] my-0 mx-auto flex flex-col items-center px-4 gap-6">
      <div className="md:w-full w-[343px] flex flex-col gap-6  ">
        {!isMobile ? (
          <My>
            <p
              onClick={() => {
                navigate('/my-payments')
              }}
            >
              내 플랜
            </p>
            {'>'}
            <p
              onClick={() => {
                navigate('/subscription')
              }}
            >
              구독 관리
            </p>
            {'>'}
            <p>구독 해지</p>
          </My>
        ) : (
          <>
            <div className="w-[335px] font-bold text-[24px] relative flex justify-center">
              <p className="">구독 해지</p>
              <p
                className=" absolute left-0"
                onClick={() => {
                  navigate('/subscription?page=1&pageSize=10')
                }}
              >
                {'<'}
              </p>
            </div>
          </>
        )}
      </div>

      <div className="w-full md:text-[32px] text-[18px] font-bold">
        <p>
          패스트컷 구독을 지금 해지하시면,
          <br />
          <span className="text-main">그 동안 받고 계셨던 혜택</span>이
          사라져요!
        </p>
      </div>
      <div className="flex flex-col w-full md:text-[20px]">
        <p>
          <span className="font-bold">사용 가능 기한</span> :{' '}
          {userSubscriptionInfo?.lastSubscriptions?.endDate}
        </p>
        <p>
          {userSubscriptionInfo?.lastSubscriptions?.endDate?.split('.')[1]}월
          {userSubscriptionInfo?.lastSubscriptions?.endDate?.split('.')[2]}
          일까지는 충분히 혜택 받으세요!
        </p>
      </div>
      <div className="flex flex-col w-full gap-6">
        <div className="flex gap-2 flex-wrap">
          {new Array(3).fill(0).map((el, index) => {
            return (
              <>
                <img
                  src={`assets/img/subscription/coupon${index}.png`}
                  alt="coupon"
                  className="w-[166px] h-[67px]"
                />
              </>
            )
          })}
        </div>
        <div className="flex gap-2 font-bold">
          <button
            className="w-[167.5px] h-[44px] rounded-full bg-[#EEF1F7] flex items-center justify-center  text-[#8F929B]"
            onClick={() => {
              setModal((prev) => !prev)
            }}
            disabled={disablePending}
          >
            혜택포기
          </button>
          <button
            className="w-[167.5px] h-[44px] rounded-full bg-main flex items-center justify-center text-white"
            onClick={() => {
              navigate('/subscription?page=1&pageSize=10')
            }}
          >
            혜택유지
          </button>
        </div>
      </div>
      <CancelModal
        isModal={isModal}
        setModal={setModal}
        handleDisableSubscription={handleDisableSubscription}
      />
    </div>
  )
}
const CancelModal = (props: any) => {
  const { isModal, setModal, handleDisableSubscription } = props
  return (
    <Modal isModal={isModal}>
      <div className="bg-white w-[335px] h-[184px] p-[20px] border-t rounded-t-[40px]">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <p className="font-bold text-[18px]">정말 구독을 해지하시겠어요?</p>
          <div className="flex flex-col text-[14px] leading-[22px]">
            지금 구독 해지하시면 <br />
            다음 결제일부터 결제되지 않습니다.
          </div>
        </div>
      </div>
      <div className="w-[335px] text-[14px]  h-[60px]  flex justify-center items-center">
        <button
          className=" w-[50%] h-full bg-[#EEF1F7] rounded-bl-[40px] border-b text-[#5E6068] border-[#EEF1F7] font-bold"
          onClick={() => {
            setModal(false)
          }}
        >
          취소
        </button>
        <button
          className="text-white w-[50%] h-full bg-main rounded-br-[40px] border-b border-main font-bold flex items-center justify-center"
          onClick={handleDisableSubscription}
        >
          확인
        </button>
      </div>
    </Modal>
  )
}
