import { useQuery } from '@tanstack/react-query'
import { getAIPromptTypeGroup, getBgm } from '../../service/list'

function useGetAIPromptGroup() {
  return useQuery({
    queryKey: ['get-aiprompt-group'],
    queryFn: () => getAIPromptTypeGroup(),
    staleTime: 6 * 1000,
  })
}

export default useGetAIPromptGroup
