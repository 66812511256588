import React, { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

export const My = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  const navigate = useNavigate()
  return (
    <div className="flex font-bold text-[#5E6068] gap-[5px]">
      <p
        onClick={() => {
          navigate('/my-page')
        }}
      >
        MY
      </p>
      <p>{`>`}</p>
      {children}
    </div>
  )
}
