import React, { useEffect, useState } from 'react'
import { ShadowBox } from '../components/ShadowBox'
import { Modal } from '../components/layout/Modal'
import { Input } from '../components/atom/Input'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import useUpdateYoutubeAuth from '../hooks/query/useUpdateYoutubeAuth'
import { useNavigate } from 'react-router-dom'

import { useYoutube } from '../hooks/query/useYoutube'
import { useDeleteYoutubeAccount } from '../hooks/query/useDeleteYotubueAccount'
import { My } from '../components/My'
import { getLocalStorage, removeLocalStorage } from '../utils/common'
import useUserStore from '../store/useUserStore'
import { isMobile } from 'react-device-detect'

export const Youtube = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const error = urlParams.get('error')
  const type = urlParams.get('type')

  const [isYoutube, setIsYoutube] = useState<boolean>(false)
  const [isCheck, setIsCheck] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(Boolean(error))
  const [isErrorModal, setIsErrorModal] = useState<boolean>(false)
  const [essentialCheck, setEssentialCheck] = useState<boolean>(false)
  const [saveSuccessModal, setSaveSuccessModal] = useState<boolean>(false)
  const [isSameYoutubeChannelId, setIsSameYoutubeChannelId] =
    useState<boolean>(true)

  const navigate = useNavigate()
  const userInfo = useUserStore((state: any) => state.userInfo)

  function moveToHome() {
    setSaveSuccessModal((prev) => !prev)
    navigate('/')
  }

  const { mutate: disConnectMutate } = useDeleteYoutubeAccount()

  const youtubeConnect = () => {
    if (userInfo) {
      const token = localStorage.getItem('token') // 토큰을 localStorage에서 가져옴
      if (token) {
        window.location.href = `${process.env.REACT_APP_API_URL}/auth/youtube?token=${token}`
      } else {
        removeLocalStorage('token')
        alert('다시 로그인하세요.')
      }
    } else {
      removeLocalStorage('token')
      alert('login이 필요합니다.')
    }
  }

  const youtubeDisConnect = () => {
    if (userInfo?.userInfo) {
      const { youtubeAccount, youtubeChannel } = userInfo.userInfo

      disConnectMutate(
        { accountId: youtubeAccount.id, channelId: youtubeChannel[0].id },
        {
          onSuccess: () => {
            setIsYoutube(false)
            window.location.reload()
          },
          onError: (e) => {
            console.log(e)
          },
        }
      )
    }
  }

  const checkAdvancedFlag = () => {
    const youtubeAccount = userInfo?.userInfo?.youtubeAccount
    const youtubeChannel = userInfo?.userInfo?.youtubeChannel[0]
    const advancedFlag = youtubeChannel?.advancedFlag
    const str = `[${youtubeAccount?.youtubeChannelName}] 채널 ${youtubeAccount?.email}의 편집자(제한적)로 권한이 부여되었습니다.`
    if (youtubeAccount && youtubeChannel && advancedFlag) {
      return (
        <>
          <p className=" leading-[28px] text-main">
            <span className="font-bold">{`[${youtubeAccount?.youtubeChannelName}]`}</span>{' '}
            채널
            <span className="font-bold">{`${youtubeAccount?.email}`}</span>의
            <span className="font-bold"> 고급 기능 인증</span>이 완료되었습니다.
          </p>
        </>
      )
    } else {
      return (
        <p>
          1번 항목에서 연결한 채널의{' '}
          <span className="font-bold">
            {`유튜브 스튜디오 > 설정 > 채널 > 기능 사용 자격 요건`}을{' '}
          </span>
          <span className="text-error font-bold">고급 기능</span>
          까지
          <span className="font-bold">
            {` 모두 신청하여 인증 완료해주세요.`}
          </span>
          <span className="text-[#8F929B]">
            (영상에 링크를 넣으려면 필수요건입니다.)
          </span>
        </p>
      )
    }
  }

  const checkYoutubeAuth = (channelName = '', email = '') => {
    const youtubeAccount = userInfo?.userInfo?.youtubeAccount
    const youtubeChannel = userInfo?.userInfo?.youtubeChannel[0]
    const youtubeAuthorityFlag = youtubeChannel?.youtubeAuthorityFlag

    //* 프리미엄 및 울트라인데 연결은 했지만 아직 권한 확인이 안되어있을때 youtubeAccount가 있는데 youtubeChannel도  있는데 권한flag가 false일대
    if ((!youtubeAccount && !youtubeChannel) || !youtubeAuthorityFlag) {
      return (
        <>
          <p className=" leading-[28px] text-[#2E343D]">
            1번 항목에서 연결한 채널의
            <span className="font-bold">{` 유튜브 스튜디오 > 설정 > 권한 > 초대`}</span>
            에서 <span className="font-bold">coo@capbox.kr</span>을
            <span className="font-bold"> 편집자(제한적)</span>로 초대해 주세요!
          </p>
        </>
      )
    } else if (
      youtubeAccount &&
      youtubeAccount.youtubeChannelId === youtubeChannel.channelId &&
      youtubeAuthorityFlag
    ) {
      // 프리미엄 및 울트라인데 연결 됬고 권한 확인도 되었을 때
      //? youtubeAccount 존재하고 youtubeAccount.youtubeChannelId 와 youtubeChannel.channelId가 일치하는 경우
      return (
        <>
          <p className=" leading-[28px] text-main">
            <span className="font-bold">{`[${youtubeAccount?.youtubeChannelName}]`}</span>{' '}
            채널 <span className="font-bold">{`${youtubeAccount?.email}`}</span>
            의 <span className="font-bold">편집자(제한적)</span>로 권한이{' '}
            부여되었습니다.
          </p>
        </>
      )
    } else if (!youtubeAccount && youtubeChannel) {
      // 연결은 안되어있는데 유튜브 채널만 있는 경우 (기존의 연결된 권한이 있는 경우)
      //? youtubeAccount 존재하고 youtubeAccount.youtubeChannelId 와 youtubeChannel.channelId가 일치하는 경우
      return (
        <>
          <p className=" leading-[28px] text-main font-bold break-words">
            <a
              href={`https://www.youtube.com/channel/${youtubeChannel?.channelId}`}
              className=" underline"
              target="_blank"
              rel="noreferrer"
            >
              <span>채널 </span>
              {`https://www.youtube.com/channel/${youtubeChannel?.channelId}`}
            </a>
            <span> 편집자(제한적)로 권한이 부여되었습니다.</span>
          </p>
        </>
      )
    } else if (
      youtubeAccount &&
      youtubeAccount.youtubeChannelId !== youtubeChannel.channelId
    ) {
      // 지금 연결된 계정이 전에 권한을 준 계정과 다른 경우
      //! youtubeAccount 존재하고 youtubeAccount.youtubeChannelId 와 youtubeChannel.channelId가 불일치하는 경우
      return (
        <>
          <p className=" leading-[28px] text-error font-bold">
            <span>채널 </span>
            <a
              href={`https://www.youtube.com/channel/${youtubeChannel?.channelId}`}
              className=" underline"
              target="_blank"
              rel="noreferrer"
            >
              {`https://www.youtube.com/channel/${youtubeChannel?.channelId}`}
            </a>
            <span> 편집자(제한적)로 권한이 부여되었습니다.</span>
          </p>
        </>
      )
    }
  }
  useEffect(() => {
    // 이페이지에 들어오지 못하는 경웅
    // 1. userInfo가 없는 경우
    // 2. userInfo.userType이 normal 이나 light 인 경우

    // if (!userInfo?.userInfo || userInfo.userType === 'userNormal') {
    //   navigate('/')
    // }

    // 연결된 youtube channleId와  예전에 연결되었던 channelId가 일치하는지 확인
    if (
      userInfo?.userInfo?.youtubeAccount &&
      userInfo?.userInfo?.youtubeAccount?.youtubeChannelId !==
        userInfo?.userInfo?.youtubeChannel[0]?.channelId
    ) {
      setIsSameYoutubeChannelId(false)
    } else {
      setIsSameYoutubeChannelId(true)
    }

    if (userInfo?.userInfo?.youtubeAccount) {
      setIsYoutube(true)
    } else {
      setIsYoutube(false)
    }
  }, [userInfo])

  useEffect(() => {
    if (isError) {
      setIsErrorModal(isError)
    }
  }, [isError])

  return (
    <>
      <div className="md:max-w-[816px] max-w-[375px] my-0 mx-auto flex flex-col items-center px-[16px]">
        <div className="w-[343px] md:w-[906px]">
          <My>
            <p>자동 업로드 설정</p>
          </My>
          <div className="flex flex-col md:gap-4  gap-2 items-center mt-[24px] md:w-[906px]">
            <p className="text-[24px] md:text-[40px] font-bold">
              자동 업로드 설정
            </p>
            <p className="md:text-[24px]  text-base">
              패스트컷 AI <span className="font-bold">상품 영상</span>의 완벽한
              사용을 위해
            </p>
            {isMobile ? (
              <div className="flex items-center gap-2">
                <p className="font-bold md:text-[32px] text-[20px] text-center">
                  <span className="text-[#ED3023]">STEP 3 까지</span> 꼭
                  완료하셔야
                  <br />
                  {` `}
                  <img
                    src="assets/img/icon_youtube.png"
                    alt="youtube"
                    width={25}
                    height={25}
                    className=" inline-block mr-1"
                  />
                  <span className="text-[#ED3023]">자동 업로드가 시작</span>
                  됩니다!
                </p>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <img
                  src="assets/img/icon_youtube.png"
                  alt="youtube"
                  width={53}
                  height={53}
                />
                <p className="font-bold text-[32px]  ">
                  <span className="text-[#ED3023]">STEP 3 까지</span> 꼭
                  완료하셔야
                  {` `}
                  <span className="text-[#ED3023]">자동 업로드가 시작</span>
                  됩니다!
                </p>
              </div>
            )}
          </div>

          <div className="flex flex-col items-center justify-center mt-2">
            <p className="text-error">
              (컨텐츠 영상은 자동업로드 설정 기능이 아직 없습니다)
            </p>
          </div>
        </div>
        <div className="mt-[40px] w-[343px] md:w-[816px]">
          <div
            className={`rounded-[20px] md:border-[2px] ${
              isSameYoutubeChannelId
                ? 'md:border-[#EEF1F7]'
                : 'md:border-[#FD6767]'
            } md:shadow-main flex flex-col  gap-[32px] md:p-[40px] `}
          >
            <div className="w-full md:w-[736px]">
              <p className="md:text-[24px] text-[20px] font-bold ">
                STEP 1. 운영하는 유튜브 채널 연결
              </p>
              <p className="mt-[6px] leading-[19.2px]">
                제작된 영상을 등록할 본인의 유튜브 채널을 연결해주세요. 정확하고
                안전한 연결을 위해 구글 로그인을 사용합니다.
              </p>
              {!isSameYoutubeChannelId && (
                <>
                  <p className="text-error">
                    연결하신 유튜브 채널과 편집자 권한이 부여된 채널의 정보가
                    다릅니다. 이 경우 자동업로드가 실행되지 않습니다. 현재
                    연결하신 채널로 운영 채널 변경을 원하시는 경우, 해당
                    채널에서 권한부여를 다시 해주세요!
                  </p>
                </>
              )}

              {isYoutube ? (
                <p className="flex justify-between items-center gap-[4px]  h-[59px] rounded-[10px] bg-[#F6F8FB] p-[16px] border-[1px] border-[#D9DEE8] mt-[6px]">
                  <p className="flex gap-2 items-center">
                    <img
                      src={userInfo?.userInfo?.youtubeAccount?.picture}
                      alt="profile_image"
                      className=" rounded-full"
                      width={30}
                      height={30}
                    />
                    <span className="text-[#5E6068]">
                      {userInfo?.userInfo?.youtubeAccount?.youtubeChannelName}
                    </span>
                  </p>
                  {userInfo?.userInfo &&
                  userInfo.userInfo?.youtubeChannel[0] &&
                  userInfo.userInfo?.youtubeChannel[0].youtubeAuthorityFlag ? (
                    ''
                  ) : (
                    <button
                      onClick={youtubeDisConnect}
                      className="bg-[#FFFFFF] border-[1px] border-[#D9DEE8] h-[43px] w-[92px]"
                    >
                      연결 끊기
                    </button>
                  )}
                </p>
              ) : (
                <>
                  <div className="w-full bg-[#F6F8FB]  p-[20px] rounded-[20px] flex justify-start flex-col items-start border-[2px] border-[#D9DEE8] gap-[12px] mt-[8px]">
                    <p className="flex gap-[12px] items-center">
                      <input
                        type="checkbox"
                        className="w-[20px] h-[20px]"
                        checked={isCheck}
                        onClick={() => {
                          setIsCheck(!isCheck)
                        }}
                      />
                      <span className="font-bold">
                        아래 1, 2번 항목에 모두 동의합니다.
                      </span>
                    </p>
                    <div className="">
                      <p className="mb-[12px]">
                        1. 유튜브 채널 연결시에는 아래의 정보가 패스트컷에
                        제공됩니다.
                      </p>
                      <ul className=" list-disc pl-4 text-[#8F929B]">
                        <li>유튜브 채널명, 채널id 및 채널 커스텀 주소</li>
                        <li>
                          패스트컷의 사용 및 Google API로부터 받은 정보의 전송은
                          Google API Services User Data Policy를 준수합니다.
                          <span className="text-[#4360EC]">
                            {' '}
                            <a
                              href="https://developers.google.com/terms/api-services-user-data-policy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (Google API Services User Data Policy)
                            </a>
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="">
                      <p className="mb-[12px]">
                        2. 영상 생성 요청시에는 아래의 정보가 OpenAI의 AI 모델인
                        ChatGPT에 제공됩니다. 아래 정보 외에 사용자의 개인정보는
                        제3자 운영의 AI 모델에 전달되지 않습니다.
                      </p>
                      <ul className=" list-disc pl-4 text-[#8F929B]">
                        <li>사용자가 선택한 상품의 URL 및 해당 상품의 정보</li>
                        <li>사용자가 기재한 상품명 및 유튜브 영상 제목</li>
                      </ul>
                    </div>
                  </div>
                  <p
                    className={`flex bg-[#F6F8FB] justify-center items-center gap-[4px] w-[178px] h-[48px] rounded-[10px] mt-[8px] font-bold border-[1px] border-[#B6BBC6] ${
                      !isCheck && 'opacity-[0.3]'
                    }`}
                  >
                    <img
                      src="assets/img/icon_youtube.png"
                      alt="youtube"
                      width={18}
                      height={18}
                    />
                    <button onClick={youtubeConnect} disabled={!isCheck}>
                      Youtube에 연결
                    </button>
                  </p>
                </>
              )}
            </div>
            <div className="w-full md:w-[736px] flex flex-col gap-[8px] ">
              <div className="md:text-[24px] text-[20px] font-bold ">
                <p>STEP 2. 편집자 권한부여</p>
              </div>
              <div className="flex flex-col gap-[8px]">
                <div className="text-[#8F929B] flex flex-col gap-[4px]">
                  {checkYoutubeAuth()}
                  <p className="">
                    {`(제한적 권한 : 콘텐츠등록 수정 가능. 권한 관리, 콘텐츠 삭제,
                    수익 데이터 확인 불가능)`}
                  </p>
                </div>
                <div className="flex flex-col md:flex-row gap-[4px]">
                  <p className=" text-[#8F929B] ">
                    패스트컷이 만든 쉬운 가이드를 참고하시면 10초컷!
                  </p>
                  <p className="hidden md:inline-block">:</p>
                  <p className="text-[#464DEE] font-bold">
                    <a
                      href="https://blog.fastseller.shop/fastcutai_youtube_upload_guide/"
                      target="_blank"
                      rel="noreferrer"
                    >{`유튜브 권한부여 가이드`}</a>{' '}
                    {`>>`}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-[736px] flex flex-col gap-[8px] ">
              <div className="md:text-[24px] text-[20px] font-bold flex md:items-center gap-1 md:flex-row flex-col">
                <p>STEP 3. 유튜브 고급 기능 신청</p>
                <span className="text-[#ED3023] text-base">
                  {' '}
                  🚨 중요 잊지마세요!!{' '}
                </span>
              </div>
              <div className="flex flex-col gap-[8px]">
                {checkAdvancedFlag()}

                <div className="flex flex-col md:flex-row gap-[4px]">
                  <p className=" text-[#8F929B] ">
                    패스트컷이 만든 쉬운 가이드를 참고하시면 10초컷!
                  </p>
                  <p className="hidden md:inline-block">:</p>
                  <p className="text-[#464DEE] font-bold">
                    <a
                      href="https://blog.fastseller.shop/youtubeadvancedverification/"
                      target="_blank"
                      rel="noreferrer"
                    >{`유튜브 고급기능 신청 가이드`}</a>{' '}
                    {`>>`}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {essentialCheck && (
            <Modal isModal={essentialCheck}>
              <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
                <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                  <p className="font-bold text-[18px]">🙌 필수 정보 미입력</p>
                  <p className="text-[14px]">
                    <span className="text-main font-bold">운영 채널 정보</span>
                    를 모두 입력해주세요!
                  </p>
                </div>
              </div>
              <div className="w-[400px] bg-main h-[60px] border-b border-main rounded-b-[20px] flex justify-center items-center">
                <button
                  className="text-white w-[400px]"
                  onClick={() => {
                    setEssentialCheck(false)
                  }}
                >
                  확인
                </button>
              </div>
            </Modal>
          )}
          {saveSuccessModal && (
            <Modal isModal={saveSuccessModal}>
              <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
                <div className="flex flex-col items-center justify-center h-full gap-[12px]">
                  <p className="font-bold text-[18px]">
                    정보가 저장되었습니다.
                  </p>
                  <div className="flex flex-col">
                    <p className="text-[14px]">
                      패스트컷이 정보를 확인한 후, 권한 부여 과정을 완료합니다.
                    </p>
                    <p className="text-[14px] font-bold">
                      권한부여가 성공적으로 완료되면
                    </p>
                    <p className="text-[14px]">
                      생성된 영상이 자동으로 등록됩니다.
                    </p>
                    <p className="text-[14px] font-bold">
                      권한 부여가 완료되지 않은 경우
                    </p>
                    <p className="text-[14px]">
                      해당 상태와 해결방법을 메일로 안내드려요.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[400px] bg-main h-[60px] border-b  border-main rounded-b-[20px] flex justify-center items-center">
                <button className="text-white w-[400px]" onClick={moveToHome}>
                  확인
                </button>
              </div>
            </Modal>
          )}

          {isErrorModal && (
            <Modal isModal={isErrorModal}>
              <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
                <div className="flex flex-col items-center justify-center h-full gap-[12px]">
                  <p className="font-bold text-[18px]">
                    {type === '1' ? '채널 연결 오류' : '채널 연결 중복'}
                  </p>
                  <div className="flex flex-col">
                    {type === '1' ? (
                      <p className="text-[14px]">
                        현재 편집자 권한이 부여된 다른 유튜브 채널이 있어,
                        <br />
                        선택하신 채널로 연결이 불가능합니다.
                        <br /> 운영 채널을 변경하시려면
                        <br /> 패스트컷 관리자에게 카카오톡으로 문의해주세요!
                      </p>
                    ) : (
                      <p className="text-[14px]">
                        선택하신 채널을 이미 연결한 다른 계정이 있어요!
                        <br /> 동일한 채널을 서로 다른 계정에서 연결할 수
                        없습니다.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-[400px] bg-main h-[60px] border-b  border-main rounded-b-[20px] flex justify-center items-center">
                <button
                  className="text-white w-[400px]"
                  onClick={() => {
                    setIsErrorModal(false)
                    navigate('/youtube-auth')
                  }}
                >
                  확인
                </button>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  )
}
