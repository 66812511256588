import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Me } from '../../../service/user'
import { BgmFactor } from '../../../service/list'
import { Control, Controller } from 'react-hook-form'
import { CircularCheckbox } from '../../CircularCheckbox'
import { FormValues } from '../../../utils/type'
import { PlanTag } from '../../planTag'
import { Modal } from '../../layout/Modal'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

interface BgmDisplayProps {
  watch: (name: 'bgmId', defaultValue?: number | undefined) => number
  setValue: (
    name: 'bgmId',
    value: number,
    options?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
          shouldTouch: boolean
        }>
      | undefined
  ) => void
  control: Control<FormValues, any>
  userInfo: Me
  bgmList: BgmFactor[] | []
  translatePlanName: (planName: string) => string
  bgmRefs: React.MutableRefObject<(HTMLAudioElement | null)[]>
  setBgmPlaying: Dispatch<SetStateAction<boolean[]>>
  bgmPlaying: boolean[]
  handleBgmPlayButtonClick: (status: boolean, index: number) => void
}

export const BgmDisplay = ({
  watch,
  setValue,
  control,
  userInfo,
  bgmList,
  bgmRefs,
  translatePlanName,
  setBgmPlaying,
  bgmPlaying,
  handleBgmPlayButtonClick,
}: PropsWithChildren<BgmDisplayProps>) => {
  const [isListModalOpen, setListModalOpen] = useState<boolean>(false)
  const findBgmNameById = () => {
    const [bgm] = bgmList.filter((el) => el.id === watch('bgmId'))
    return bgm?.name
  }
  const handleListModalOpen = () => {
    setListModalOpen((prev) => !prev)
  }

  useEffect(() => {
    if (isListModalOpen) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isListModalOpen])

  return (
    <>
      <div className="bgm  mb-10">
        <div className="flex md:flex-row flex-col md:items-center justify-between">
          <p className="font-bold text-[18px] mb-4 ">배경음악</p>
          <p className="md:w-[630px] w-full h-[61px] rounded-[10px] bg-[#F6F8FB] px-4 py-5 flex items-center justify-between font-bold">
            <span className="">{findBgmNameById()}</span>
            <button
              className="px-2 py-[6px] bg-[#2E343D] text-[white] rounded-[4px] text-[14px]"
              onClick={handleListModalOpen}
              type="button"
            >
              선택 {'>'}
            </button>
          </p>
        </div>
      </div>
      <Modal isModal={isListModalOpen}>
        <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
          <div
            className={classNames('', {
              'bg-white md:w-[816px] h-auto  shadow-main rounded-[20px] md:p-8  flex flex-col   gap-8':
                !isMobile,
              'bg-white w-full h-full  shadow-main   flex flex-col   gap-8 py-10 px-4  ':
                isMobile,
            })}
          >
            <div className="flex flex-col  gap-4  ">
              <p
                className={classNames('flex items-center', {
                  'justify-center': isMobile,
                  'justify-between': !isMobile,
                })}
              >
                <span className="font-bold text-[24px] text-left">
                  배경음악
                </span>
                {/* <button
                  onClick={() => {
                    setListModalOpen((prev) => !prev)
                  }}
                  className=""
                >
                  <img
                    src="assets/img/Icon_x.png"
                    alt="icon_x"
                    height={40}
                    width={40}
                  />
                </button> */}
              </p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-x-[22px] gap-y-[16px]">
              {bgmList?.map((bgm, index) => {
                return (
                  <>
                    <div
                      className="flex items-center  justify-between space-x-2  w-full md:w-[228px]"
                      key={index}
                    >
                      <div className="flex">
                        <Controller
                          render={({ field }) => (
                            <CircularCheckbox
                              {...field}
                              id={index}
                              checked={watch('bgmId') === bgm.id}
                              onChange={() => setValue('bgmId', bgm.id)}
                              disabled={
                                !userInfo ||
                                userInfo?.userType === 'normalUser' ||
                                userInfo?.userType === 'lightUser'
                              }
                              label={bgm.name}
                            />
                          )}
                          name="bgmId"
                          control={control}
                        />
                        <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                          <PlanTag
                            planName={translatePlanName(bgm?.planName)}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="flex gap-[12px]">
                        <audio
                          ref={(ref) => {
                            bgmRefs.current[index] = ref
                            if (ref) {
                              ref.onended = () => {
                                const updatedBgmPlayingState = [...bgmPlaying]
                                updatedBgmPlayingState[index] = false
                                setBgmPlaying(updatedBgmPlayingState)
                              }
                            }
                          }}
                        >
                          <source src={bgm.path} type="audio/mpeg" />
                        </audio>
                        <button
                          type="button"
                          onClick={() => {
                            handleBgmPlayButtonClick(!bgmPlaying[index], index)
                          }}
                        >
                          <img
                            src={
                              !bgmPlaying[index]
                                ? 'assets/img/icon_play.png'
                                : 'assets/img/icon_pause.png'
                            }
                            alt="play"
                            className="w-[29.25px] h-[29.25px]"
                          />
                        </button>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
            <div className="py-5">
              <button
                className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                onClick={handleListModalOpen}
                type="button"
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
