import { create } from 'zustand'

interface Store {
  signOutModal: boolean
  setSignOutModal: (value: boolean) => void
}

export const useStore = create<Store>((set) => ({
  signOutModal: false,
  setSignOutModal: (value) => set({ signOutModal: value }),
}))
