import { useQuery } from '@tanstack/react-query'
import { getVoiceTag } from '../../service/list'

function useGetVoiceTag() {
  return useQuery({
    queryKey: ['get-voice-tag'],
    queryFn: () => getVoiceTag(),
    staleTime: 6 * 1000,
  })
}

export default useGetVoiceTag
