import { useQuery } from '@tanstack/react-query'

import { loadBrandPay } from '@tosspayments/brandpay-sdk'
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  AffiliateBenefit,
  AffiliatePlan,
  CouponBenefit,
  DefaultBenefit,
  DiscountType,
  InviteBenefit,
  PaymentOrderRequest,
} from '../../service/payment'
import { UserInfoType } from '../../service/user'
import { Input } from '../atom/Input'
import { useDiscount } from '../../hooks/query/useDiscount'
import { useSaveDiscount } from '../../hooks/query/useSaveDiscount'
import { isMobile } from 'react-device-detect'
import { Modal } from '../layout/Modal'
import { CSSTransition } from 'react-transition-group'
import useGetBillingCustomerKey from '../../hooks/query/brandpay/useGetBillingCustomerKey'
import useGetBillingMethods from '../../hooks/query/brandpay/useGetBillingMethods'

import classNames from 'classnames'
import usePostBrandPayTransaction from '../../hooks/query/brandpay/usePostBrandPayTransaction'
import usePutBillingMethods from '../../hooks/query/brandpay/usePutBillingMethods'
import { useNavigate } from 'react-router-dom'
import { Spinner } from '../atom/Spinner'
import useUserStore from '../../store/useUserStore'
interface ModalProps {
  setPaymentModal: Dispatch<SetStateAction<boolean>>
  setCouponBenefitData: Dispatch<SetStateAction<CouponBenefit | null>>
  setAffiliateBenefitData: Dispatch<SetStateAction<AffiliateBenefit | null>>
  paymentOrderRequest: PaymentOrderRequest
  affiliateBenefit: AffiliateBenefit | null
  inviteBenefit: InviteBenefit | null
  affiliatePlan: AffiliatePlan[] | null
  couponBenefit: CouponBenefit | null
  userInfo: UserInfoType
  planId: number
  type: string
  upgrade?: boolean
  monthPrice?: number
  defaultBenefit: DefaultBenefit | null
  setDefaultBenefitData: Dispatch<SetStateAction<DefaultBenefit | null>>
  customerKey: string
  planPriceId: number
}

export const BrandPaymentModal = ({
  children,
  paymentOrderRequest,
  affiliateBenefit,
  couponBenefit,
  inviteBenefit,
  affiliatePlan,
  setPaymentModal,
  userInfo,
  planId,
  type,
  upgrade,
  monthPrice,
  setCouponBenefitData,
  setAffiliateBenefitData,
  defaultBenefit,
  setDefaultBenefitData,
  customerKey,
  planPriceId,
}: PropsWithChildren<ModalProps>) => {
  const clientKey = process.env.REACT_APP_BRAND_PAY_KEY
    ? process.env.REACT_APP_BRAND_PAY_KEY
    : ''

  const {
    data: billingMethods,
    refetch: billingMethodsFetch,
    isPending,
  } = useGetBillingMethods()
  const {
    mutate: brandPayTransactionMutate,
    isPending: brandPayTransactionPending,
  } = usePostBrandPayTransaction()
  const { mutate: putBillingMethodsMutate } = usePutBillingMethods()

  const [brandpay, setBrandpay] = useState<any>(null)

  const [isAffiliateBenefit, setAffiliateBenefit] = useState<boolean>(false)
  const [isCouponBenefit, setCouponBenefit] = useState<boolean>(false)
  const [isDefaultBenefit, setDefaultBenefit] = useState<boolean>(false)

  const [isModal, setModal] = useState<boolean>(false)
  const [errorText, setErrorText] = useState({ title: <></>, subtitle: <></> })
  const navigate = useNavigate()
  const [invitationCode, setInvitationCode] = useState<string>('')
  const [totalPrice, setTotalPrice] = useState<number>(
    paymentOrderRequest?.price + paymentOrderRequest?.vat
  )
  const [originPrice, setOriginPrice] = useState<number>(
    paymentOrderRequest?.price
  )
  const [modifyPrice, setModifyPrice] = useState<number>(
    paymentOrderRequest?.price
  )
  const [modifyVat, setModifyVat] = useState<number>(paymentOrderRequest?.vat)

  const [discountValue, setDiscountValue] = useState<number>(0)
  const [defaultDiscountValue, setDefaultDiscountValue] = useState<number>(0)

  const [isChecked, setIsChecked] = useState(false)

  const [billingMethodDetailId, setBillingMethodDetailId] = useState<number>(0)

  const handleBillingMethodDetail = (billingMethodDetailId: number) => {
    putBillingMethodsMutate(billingMethodDetailId, {
      onSuccess: (res) => {
        billingMethodsFetch()
      },
      onError: () => {},
    })
  }

  const openSetting = async () => {
    if (brandpay) {
      try {
        const res = await brandpay.openSettings()

        // setEnabled((prev) => !prev)
      } catch (e) {
        console.log(e, '실패')
      }
      await billingMethodsFetch()
    }
  }

  const handleChange = (event: any) => {
    setIsChecked(event.target.checked)
  }

  const handlePayment = () => {
    //약관 동의 체크

    if (!isChecked) {
      setErrorText({
        title: (
          <>
            <p>🚨 필수 약관에 동의해주세요</p>
          </>
        ),
        subtitle: (
          <>
            <p>패스트컷 구독(정기결제) 규정 동의 항목에 체크 해주세요</p>
          </>
        ),
      })

      return setModal((prev) => !prev)
    }

    //자동결제 카드 선택 체크
    if (billingMethods) {
      if (billingMethods.filter((el) => el.selected === 1).length <= 0) {
        setErrorText({
          title: (
            <>
              <p>🚨 결제 방법을 선택해주세요</p>
            </>
          ),
          subtitle: (
            <>
              <p>
                결제 방법 {`>`} 패스트컷페이에서
                <br />
                결제하실 카드나 계좌를 선택해주세요!
              </p>
            </>
          ),
        })
        return setModal((prev) => !prev)
      }
    } else {
    }

    brandPayTransactionMutate(
      { planId: planId, planPriceId: planPriceId },
      {
        onSuccess: (res) => {
          navigate(
            `/payment-success?amount=${totalPrice}&type=plan&planId=${planId}&subscriptionType=monthly`
          )
        },
        onError: (e: any) => {
          console.log(e, '123')
          const message = e.response.data?.message
          navigate(
            `/payment-fail?path=price-guide&type=plan&message=${message}`
          )
          console.log('자동결제 실패')
        },
      }
    )
  }

  const addMethod = () => {
    console.log('brandPay method add ')
    if (brandpay) {
      brandpay
        .addPaymentMethod('카드')
        .then(async function (methods: any) {
          billingMethodsFetch()
        })
        .catch(function (error: any) {
          if (error.code === 'USER_CANCEL') {
            // 사용자가 결제창을 닫은 경우 에러 처리
          }
        })
    }
  }

  const requestAgreement = async () => {
    if (brandpay) {
      brandpay
        .requestAgreement('빌링') // 자동결제 선택 약관 동의 호출
        .then(function (data: any) {
          addMethod()
          // 성공 처리
        })
        .catch(function (error: any) {
          if (error.code === 'USER_CANCEL') {
            console.log('취소')
            // 사용자가 창을 닫아 취소한 경우에 대한 처리
          }
        })
    }
  }

  useEffect(() => {
    async function fetchBrandpay() {
      try {
        const brandpay = await loadBrandPay(clientKey, customerKey, {
          redirectUrl: process.env.REACT_APP_REDIRECT_URL,
        })

        setBrandpay(brandpay)
      } catch (error) {
        console.error('Error fetching brandpay:', error)
      }
    }

    fetchBrandpay()
    const finalPrice = originPrice - (monthPrice ? Number(monthPrice) : 0)
    const vat = Number((finalPrice * 0.1).toFixed(0))
    let tPrice = finalPrice + vat

    setTotalPrice(tPrice)
    setModifyPrice(finalPrice)
    setModifyVat(vat)

    if (
      affiliateBenefit?.discountedPrice &&
      paymentOrderRequest.subscriptionType === 'annual'
    ) {
      setAffiliateBenefit((prev) => !prev)

      const { discountAmount, discountRate, discountedPrice, originalPrice } =
        affiliateBenefit
      const finalPrice = discountedPrice - (monthPrice ? Number(monthPrice) : 0)

      const vat = Number((finalPrice * 0.1).toFixed(0))
      tPrice = finalPrice + vat

      setTotalPrice(tPrice)
      setModifyPrice(finalPrice)
      setModifyVat(vat)
      setDiscountValue(originalPrice - discountedPrice)
      if (affiliatePlan) {
        setInvitationCode(affiliatePlan[0].affiliateCode)
      }
    }
    if (couponBenefit) {
      setCouponBenefit((prev) => !prev)

      const { discountAmount, discountRate, discountedPrice, originalPrice } =
        couponBenefit
      const finalPrice = discountedPrice - (monthPrice ? Number(monthPrice) : 0)

      const vat = Number((finalPrice * 0.1).toFixed(0))
      tPrice = finalPrice + vat

      setTotalPrice(tPrice)
      setModifyPrice(finalPrice)
      setModifyVat(vat)
      setDiscountValue(originalPrice - discountedPrice)
    }
    if (defaultBenefit) {
      setDefaultBenefit((prev) => !prev)
      const { discountAmount, discountedPrice, originalPrice } = defaultBenefit
      const finalPrice = discountedPrice - (monthPrice ? Number(monthPrice) : 0)

      const vat = Number((finalPrice * 0.1).toFixed(0))
      tPrice = finalPrice + vat

      setTotalPrice(tPrice)
      setModifyPrice(finalPrice)
      setModifyVat(vat)
      setDiscountValue(originalPrice - discountedPrice)
      setDefaultDiscountValue(originalPrice - discountedPrice)
    }
  }, [])

  useEffect(() => {
    if (userInfo) {
      const userId = userInfo?.id // 고유 사용자 ID
    }
  }, [userInfo])

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center md:p-[40px] z-50">
      <div className="bg-white w-full md:w-[816px] px-[20px] md:px-[40px] border md:rounded-[20px] overflow-y-auto pb-[100px]">
        <div className="sticky top-0 bg-white z-10  pb-5 pt-10  ">
          <p className="flex justify-center items-center relative ">
            <p className="md:text-[48px] text-[32px] font-bold   ">
              구독 플랜 결제
            </p>
            <button
              onClick={() => {
                setAffiliateBenefitData(null)
                setCouponBenefitData(null)
                setDefaultBenefitData(null)
                setPaymentModal(false)
              }}
              className=" absolute right-0"
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                height={40}
                width={40}
              />
            </button>
          </p>
        </div>
        <div className="full flex justify-end">
          <span
            className="w-[10px] h-[10px] bg-white"
            onClick={openSetting}
          ></span>
        </div>
        <div className="md:mt-[32px] md:p-[32px] p-[20px] bg-[#E9F8F9] rounded-[20px] h-auto md:h-[118px] border-[2px] border-main">
          {isMobile ? (
            <>
              {' '}
              <div className="flex flex-col items-start gap-2">
                <p className="font-bold text-[18px] md:text-[24px] text-main">
                  {paymentOrderRequest.productName} 플랜
                </p>
                {(isAffiliateBenefit || isDefaultBenefit || isCouponBenefit) &&
                paymentOrderRequest.subscriptionType === 'annual' ? (
                  <>
                    <span className="mr-4">
                      {/* 기존 가격 */}
                      <s className="font-bold text-[24px] md:text-[32px] text-[#8F929B]">
                        {originPrice.toLocaleString()}원
                      </s>
                    </span>
                    <p>
                      <span className="font-bold text-[24px] md:text-[32px]">
                        {modifyPrice.toLocaleString()}원
                      </span>

                      {paymentOrderRequest.subscriptionType === 'annual' ? (
                        <span className="text-[14px] md:ext-[18px]">
                          /1년(VAT 별도)
                        </span>
                      ) : (
                        <span className="text-[14px] md:ext-[18px]">
                          /월(VAT 별도)
                        </span>
                      )}
                    </p>
                    {/* 할인된 가격 */}
                  </>
                ) : (
                  <>
                    {' '}
                    <span className="font-bold text-[24px] md:text-[32px]">
                      {originPrice.toLocaleString()}원
                    </span>
                    {paymentOrderRequest.subscriptionType === 'annual' ? (
                      <span className="text-[14px] md:ext-[18px]">
                        /1년(VAT 별도)
                      </span>
                    ) : (
                      <span className="text-[14px] md:ext-[18px]">
                        /월(VAT 별도)
                      </span>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="flex flex-col md:flex-row items-center gap-[32px]">
                <p className="font-bold text-[18px] md:text-[24px] text-main">
                  {paymentOrderRequest.productName} 플랜
                </p>
                <p className="">
                  {isAffiliateBenefit || isDefaultBenefit || isCouponBenefit ? (
                    <>
                      <span className="mr-4">
                        {/* 기존 가격 */}
                        <s className="font-bold text-[24px] md:text-[32px] text-[#8F929B]">
                          {originPrice.toLocaleString()}원
                        </s>
                      </span>
                      <span className="font-bold text-[24px] md:text-[32px]">
                        {modifyPrice.toLocaleString()}원
                      </span>

                      {paymentOrderRequest.subscriptionType === 'annual' ? (
                        <span className="text-[14px] md:ext-[18px]">
                          /1년(VAT 별도)
                        </span>
                      ) : (
                        <span className="text-[14px] md:ext-[18px]">
                          /월(VAT 별도)
                        </span>
                      )}
                      {/* 할인된 가격 */}
                    </>
                  ) : (
                    <>
                      {' '}
                      <span className="font-bold text-[24px] md:text-[32px]">
                        {originPrice.toLocaleString()}원
                      </span>
                      {paymentOrderRequest.subscriptionType === 'annual' ? (
                        <span className="text-[14px] md:ext-[18px]">
                          /1년(VAT 별도)
                        </span>
                      ) : (
                        <span className="text-[14px] md:ext-[18px]">
                          /월(VAT 별도)
                        </span>
                      )}
                    </>
                  )}
                </p>
              </div>
            </>
          )}
        </div>{' '}
        <div className="bg-white mt-[24px]">
          <p className="font-bold text-[24px] w-full text-left mb-2">
            금액 안내
          </p>
          <div className=" bg-[#F6F8FB] h-auto rounded-[20px] md:px-[32px] px-5 py-[24px] flex flex-col justify-between gap-[17px]">
            <div className="flex justify-between">
              <p className="font-bold md:text-[24px] text-[20px]">금액 상세</p>
              <p className="font-bold md:text-[24px] text-[20px]">
                {modifyPrice.toLocaleString()}원
              </p>
            </div>
            <div className="flex gap-[10px]">
              <div className="w-[4px] h-auto bg-[#D4D9DF]"></div>
              <div className="flex flex-col w-full text-[#8F929B] font-bold">
                {upgrade ? (
                  <>
                    <p className="w-full flex justify-between">
                      <span>연간 플랜 금액</span>
                      <span> {originPrice.toLocaleString()}원</span>
                    </p>
                    <p className="w-full flex justify-between">
                      <span>기존 플랜 결제 금액</span>
                      <span> -{monthPrice?.toLocaleString()}원</span>
                    </p>
                    <p className="w-full flex justify-between">
                      <span>할인 코드</span>
                      <span>
                        {' '}
                        {discountValue !== 0 ? '-' : ''}
                        {discountValue.toLocaleString()}원
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    {' '}
                    <p className="w-full flex justify-between">
                      <span>플랜 가격</span>
                      <span> {originPrice.toLocaleString()}원</span>
                    </p>
                    <p className="w-full flex justify-between">
                      {isAffiliateBenefit ? (
                        <span>제휴 할인</span>
                      ) : isCouponBenefit ? (
                        <span>미션 완료 할인</span>
                      ) : (
                        <span>연결제 할인</span>
                      )}
                      <span>
                        {' '}
                        {discountValue !== 0 ? '-' : ''}
                        {discountValue.toLocaleString()}원
                      </span>
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <p className="font-bold md:text-[24px] text-[20px] ">
                VAT(부가세 10%)
              </p>
              <p className="font-bold md:text-[24px] text-[20px]">
                {modifyVat.toLocaleString()}원
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#AFECEC] rounded-[20px] mt-[8px] flex p-[20px] md:flex-row justify-between items-center md:px-[32px] md:py-[24px] h-[78px] ">
          <p className="text-[18px] md:text-[24px] font-bold">총 결제금액</p>
          <p className="text-[24px] font-bold">
            {' '}
            {totalPrice.toLocaleString()}원
          </p>
        </div>
        {inviteBenefit && (
          <div className="bg-[#F6F8FB] rounded-[20px] mt-11 flex p-5 flex-col md:flex-row justify-between items-center md:px-[32px] md:py-[24px] md:h-[78px] gap-3 md:gap-0">
            <p className="text-[20px] text-left w-full ">
              <span className="font-bold">초대 코드 적용 혜택</span> (최초 1회)
            </p>
            <p className="text-[20px] font-bold  text-right w-full">
              {' '}
              +{(
                modifyPrice / inviteBenefit.bonusPointRate
              ).toLocaleString()}{' '}
              캐시 보너스
            </p>
          </div>
        )}
        <div className="payment-method flex flex-col my-11 gap-4">
          <p className="font-bold text-[24px] text-left">결제 방법</p>
          <p className="flex gap-1 items-center">
            <img
              src="assets/img/logo/fastcut-pay.jpg"
              alt="pay"
              className="w-[21px] h-[21px]"
            />
            <span className="font-[700] text-main">Pay</span>
            <span className="font-[700] ">패스트컷페이</span>
            <img
              src="assets/img/logo/toss.png"
              alt="logo"
              className="w-[137px] h-[20px]"
            />
          </p>
          <div className="flex gap-2 overflow-x-auto">
            {billingMethods?.map((el) => {
              return (
                <>
                  <button
                    className={classNames(
                      'w-full min-w-[300px] rounded-[10px] flex items-center  h-[84px]  px-3   gap-[10px]',
                      {
                        'border-[1px] border-[#D9DEE8]': !el.selected,
                        'border-[2px] border-main': el.selected,
                      }
                    )}
                    onClick={() => {
                      handleBillingMethodDetail(el.id)
                    }}
                  >
                    <img
                      src={el.methodIconUrl}
                      alt="icon"
                      className="w-[40px] h-[64px]"
                    />
                    <div className="flex flex-col items-start">
                      <p className="font-bold text-[14px]">
                        {el.methodName}({el.methodNumber})
                      </p>
                      <p className=" text-[14px]">카드</p>
                    </div>
                  </button>
                </>
              )
            })}
            <button
              className="w-full min-w-[300px] rounded-[10px] flex items-center border-[1px] border-[#2E343D] h-[84px] flex-col justify-center"
              onClick={() => {
                if (billingMethods) {
                  const methodLen = billingMethods?.length
                  if (methodLen <= 0) {
                    requestAgreement()
                  } else {
                    addMethod()
                  }
                }
              }}
            >
              <p className="flex gap-1 items-center">
                <img
                  src="assets/img/logo/fastcut-pay.jpg"
                  alt="pay"
                  className="w-[21px] h-[21px]"
                />
                <span className="font-[700] text-main">Pay</span>
                <span className="font-[700] ">+</span>
              </p>
              <p>패스트컷페이를 추가하고 빠르게 결제하세요</p>
            </button>
          </div>
        </div>
        <div className="w-full bg-[#F6F8FB]  p-[20px] rounded-[20px] flex justify-start flex-col items-start border-[2px] border-[#D9DEE8]">
          <p className="font-bold text-[18px] p-[10px]">환불 규정</p>
          <ul className="list-disc pl-8 text-left">
            <li>
              구독서비스 결제 후 사용이력(영상생성 요청 이력)이 전혀 없는 경우,
              결제 후 7일 이내에만 결제취소(청약철회)가 가능합니다. 단, 7일이
              지난 경우 또는 사용이력이 있는 경우에는 결제취소가
              불가능합니다(단순변심, 상세설명 미숙지, 결제취소 및 환불규정
              미숙지, 결제부주의 등으로 인한 결제의 경우 등의 사유를 포함하여
              환불이 불가능합니다).
            </li>
            <li>
              구독(정기결제) 서비스는 중도 해지(정기결제 중단) 시, 이미 결제가
              완료된 잔여기간 동안 유료 서비스를 계속 이용할 수 있습니다.
            </li>

            <li>
              당사는 당사가 부담하였거나 부담할 부대비용, 수수료를 차감하여
              환불할 수 있습니다.
            </li>
            <li>
              그 밖의 상세 규정은{' '}
              <a
                href="https://angelleague.notion.site/14dba20e97f380d3921bcef08a07b994"
                target="_blank"
                className=" underline text-href"
                rel="noreferrer"
              >
                ‘패스트컷 구독(정기결제) 규정’
              </a>
              을 따릅니다.
            </li>
          </ul>
        </div>
        <div className="flex  items-center justify-center gap-3 mt-[24px]">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
            className="w-[20px] h-[20px] shrink-0"
          />
          <p className="text-[#8F929B] text-[18px] text-left">
            [필수]{' '}
            <a
              href="https://angelleague.notion.site/14dba20e97f380d3921bcef08a07b994"
              target="_blank"
              className=" underline text-href"
              rel="noreferrer"
            >
              패스트컷 구독(정기결제) 규정
            </a>
            에 동의합니다.
          </p>
        </div>
        <div
          className={classNames(
            'w-full bg-main my-[24px] h-[60px] flex items-center justify-center rounded-[40px] text-[white] font-bold'
          )}
        >
          <button className="w-full h-full text-[18px]" onClick={handlePayment}>
            {totalPrice.toLocaleString()}원 결제하기
          </button>
        </div>
        {(brandPayTransactionPending || isPending) && (
          <>
            {' '}
            <Spinner />
          </>
        )}{' '}
      </div>
      {isModal && (
        <Modal isModal={isModal}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">{errorText.title}</p>
              <div className="flex flex-col leading-[22px]">
                <p className=" text-[14px] ">{errorText.subtitle}</p>
              </div>
            </div>
          </div>
          <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
            <button
              className="text-white w-[100%] h-full bg-main rounded-b-[20px] border-b border-main font-bold"
              onClick={() => {
                setModal((prev) => !prev)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}
