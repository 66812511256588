import { useMutation } from '@tanstack/react-query'
import {
  getBillingCustomerKey,
  putBillingDisable,
  putBillingMethods,
} from '../../../service/brandpay'

function usePutBillingDisable() {
  return useMutation({
    mutationKey: ['put-billing-methods-disable'],
    mutationFn: putBillingDisable,
  })
}

export default usePutBillingDisable
