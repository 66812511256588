import { Cookies } from 'react-cookie'
import { ErrorBox, YoutubeAuthCircle } from '../components/ErrorBox'
import { ThumbnailInfo } from '../components/thumbnail/Thumbnail'
import { useEffect, useRef, useState } from 'react'
import {
  NoUserModal,
  NoUserModalHome,
  NoUserModalMyPage,
  SecretModal,
  SecretModalHome,
  SecretModalMyPage,
} from '../components/freePlan/WelcomeBox'
import { Me, UserInfoType, UserSubscriptionInfo } from '../service/user'
import {
  PayBackBannerHome,
  PayBackBannerVideo,
} from '../components/payback/PayBackBanner'
import { subscriptionType } from '../service/plans'

export const CASH_INFO_FIRST = [
  '캐시는 패스트컷 서비스 내에서만 사용하실 수 있으며, 서비스 외부에서 사용하실 수 없습니다.',
  '1캐시는 1원입니다.',
  '사용자는 캐시로 패스트컷 플랜 등 패스트컷 서비스 내에서 안내에 따라 상품 또는 서비스를 구매할 수 있습니다.',
  '캐시는 보너스 캐시가 먼저 사용되고 현금 캐시가 사용됩니다. 보너스 캐시는 유효기간이 임박한 순으로 먼저 사용됩니다.',
  '보너스 캐시는 유효기간이 캐시마다 상이하며, 보너스캐시의 유효기간이 별도로 명시되어 있지 않은 경우 유효기간은 1개월입니다.',
  '캐시는 제3자에게 양도가 불가능합니다.',
  '캐시는 만 14세 미만의 아동이 구매할 수 없습니다.',
]

export const CASH_INFO_SECOND = [
  '결제 후 캐시 사용이력이 전혀 없는 경우, 결제 후 7일 이내에 환불이 가능합니다.',
  '7일이 지난 경우에는 미사용 캐시에 대하여 취소수수료(환불요청액의 10%)를 공제한 후 환불되나, 2천 캐시(2천원) 미만은 환불이 불가능합니다. 단, 취소수수료가 2천원보다 작은 경우에는 2천원을 취소수수료로 공제합니다.',
  '현금 캐시는 결제 후 1년간 미사용한 경우 기간 만료로 소멸됩니다.',
  '충전된 캐시의 이용기간과 환불가능기간은 결제시점으로부터 1년 이내로 제한됩니다.',
  '구매한 캐시가 아닌 보너스로 지급된 캐시는 현금으로 환불되지 않습니다.',
]
export enum UserType {
  USER = 'normalUser',
  라이트 = 'lightUser',
  프로 = 'proUser',
  프리미엄 = 'premiumUser',
  체험 = 'experienceUser',
}

export enum ChallengeStatusEnum {
  ACTIVATE = 'activate',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
}

export const PREV_THUMBNAIL_INIT = { id: 0, index: 0 }
export const THUMBNAILINFO_LIST = 't_list'
export const LOCAL_THUMBNAIL_INFO = 'local_t'
export const THUMBNAIL_INFO: ThumbnailInfo = {
  channelName: '',
  title1: '',
  title2: '',
  title3: '',
}
export const saveLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    console.log('::::: Error save local storage :::::', err)
  }
}

export const getLocalStorage = (key: string) => {
  try {
    const items = localStorage.getItem(key)
    if (items) {
      return JSON.parse(items)
    }
  } catch (err) {
    console.log('::::: Error get local storage :::::', err)
    return null
  }
}

export const getUtmLocalStorage = () => {
  try {
    const utm_source = localStorage.getItem('utm_source')
    const utm_campaign = localStorage.getItem('utm_campaign')
    if (utm_source && utm_campaign) {
      return {
        utmSource: JSON.parse(utm_source),
        utmCampaign: JSON.parse(utm_campaign),
        trackType: '',
      }
    }
  } catch (err) {
    console.log('::::: Error get local storage :::::', err)
    return null
  }
}
export const removeLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key)
  } catch (err) {
    console.log('::::: Error remove local storage :::::', err)
  }
}

export const showErrorBox = (userInfo: any) => {
  if (!userInfo) {
    return
  }
  if (userInfo?.userInfo) {
    const { userType, userInfo: user } = userInfo
    const { youtubeAccount, youtubeChannel } = user

    if (
      (userType === UserType.프로 ||
        userType === UserType.프리미엄 ||
        userType === UserType.체험) &&
      !youtubeAccount
    ) {
      return (
        <>
          <ErrorBox />
        </>
      )
    } else if (
      (userType === UserType.프로 ||
        userType === UserType.프리미엄 ||
        userType === UserType.체험) &&
      youtubeAccount
    ) {
      if (
        !youtubeChannel[0]?.youtubeAuthorityFlag ||
        !youtubeChannel[0].advancedFlag
      ) {
        return (
          <>
            <ErrorBox />
          </>
        )
      }
    }
  }
}

export const showYoutubeAuth = (userInfo: any, className: string) => {
  if (!userInfo) {
    return
  }
  if (userInfo?.userInfo) {
    const { userType, userInfo: user } = userInfo
    const { youtubeAccount, youtubeChannel } = user

    if (
      (userType === UserType.프로 ||
        userType === UserType.프리미엄 ||
        userType === UserType.체험) &&
      !youtubeAccount
    ) {
      return (
        <>
          <YoutubeAuthCircle className={className} />
        </>
      )
    } else if (
      (userType === UserType.프로 ||
        userType === UserType.프리미엄 ||
        userType === UserType.체험) &&
      youtubeAccount
    ) {
      if (
        !youtubeChannel[0]?.youtubeAuthorityFlag ||
        !youtubeChannel[0].advancedFlag
      ) {
        return (
          <>
            <YoutubeAuthCircle className={className} />
          </>
        )
      }
    }
  }
}

const cookies = new Cookies()
export const setCookie = (
  name: string,
  value: string,
  exDay: number,
  options?: any
) => {
  const expires = new Date()
  expires.setDate(expires.getDate() + exDay) // 만료일 설정
  //expires.setSeconds(expires.getSeconds() + 30) // 30초 동안 유효
  cookies.set(name, value, { path: '/', expires, ...options })
}

export const getCookie = (name: string) => {
  return cookies.get(name)
}

export const MOBILE_NO_USER_BTN_LIST = [
  {
    name: '상품 영상(기존 롱폼) 만들기',
    path: 'video-editor',
    event: false,
    showForLoggedIn: false,
    showForLoggedOut: true,
  },

  {
    name: '컨텐츠 영상 만들기',
    path: 'short-video-editor',
    event: false,
    showForLoggedIn: false,
    showForLoggedOut: true,
  },
  {
    name: '상품가격',
    path: 'price',
    event: false,
    showForLoggedIn: true,
    showForLoggedOut: true,
  },
  {
    name: '자주 묻는 질문',
    path: 'faq',
    event: false,
    showForLoggedIn: false,
    showForLoggedOut: true,
  },
  {
    name: '챌린지',
    path: 'challenge',
    event: true,
    showForLoggedIn: true,
    showForLoggedOut: true,
  },
  {
    name: '글로벌숏폼 🌏',
    path: 'global',
    event: false,
    showForLoggedIn: true,
    showForLoggedOut: true,
  },
]

export const MOBILE_SELECT_BTN_LIST = [
  {
    name: '마이페이지',
    path: 'my-page',
    user: true,
  },
  {
    name: '상품 영상(기존 롱폼) 만들기',
    path: 'video-editor',
    user: false,
  },
  {
    name: '컨텐츠 영상 만들기',
    path: 'short-video-editor',
    user: false,
  },
  { name: '사용 현황', path: 'my-videos', user: true },

  { name: '캐시 관리', path: 'my-cash-dashboard', user: true },
  { name: '내 플랜', path: 'my-payments', user: true },
  { name: '자동 업로드 설정', path: 'youtube-auth', user: true },
  { name: '블로그', path: 'blog', user: true },
  { name: '자주 묻는 질문', path: 'faq', user: true },
  { name: '로그아웃', path: 'logout', user: true },
]

export const PC_SELECT_BTN_LIST = [
  {
    name: '상품 영상(기존 롱폼) 만들기',
    path: 'video-editor',
  },
  {
    name: '컨텐츠 영상 만들기',
    path: 'short-video-editor',
  },
  { name: '사용 현황', path: 'my-videos' },
  { name: '캐시 관리', path: 'my-cash-dashboard' },
  { name: '내 플랜', path: 'my-payments' },
  { name: '자동 업로드 설정', path: 'youtube-auth' },
  { name: '블로그', path: 'blog' },
  { name: '자주 묻는 질문', path: 'faq' },
  { name: '로그아웃', path: 'logout' },
]
export const GNB = [
  {
    name: '상품 영상(기존 롱폼)',
    path: 'video-editor',
    textMain: true,
    event: false,
  },
  {
    name: '컨텐츠 영상',
    path: 'short-video-editor',
    textMain: true,
    event: false,
  },
  { name: '가격 안내', path: 'price-guide', textMain: true, event: false },
  // { name: '자주 묻는 질문', path: 'faq', textMain: true, event: false },
  { name: '챌린지', path: 'challenge', event: true },
  { name: '글로벌숏폼 🌏', path: 'global', event: false },
]

export const transitionSort: { key: 'id' | 'sort'; name: string }[] = [
  {
    key: 'sort',
    name: '추천순',
  },
  {
    key: 'id',
    name: '최신순',
  },
]

export const outroSort: { key: 'id' | 'sort'; name: string }[] = [
  {
    key: 'sort',
    name: '추천순',
  },
  {
    key: 'id',
    name: '최신순',
  },
]

export const extractNaverBlogURL = (url: string) => {
  const pattern = /https:\/\/m\.blog\.naver\.com\/[^\/]+/
  const match = url.match(pattern)
  return match ? match[0] : null
}

export const extractTistoryBlogURL = (url: string) => {
  const pattern = /https:\/\/[^\/]+\.tistory\.com/
  const match = url.match(pattern)
  return match ? match[0] : null
}

export const checkBlogExists = (url: string) => {
  const newUrl = transformBlogURL(url)
  return newUrl ?? url
}

export const checkClipExists = (url: string) => {
  const newUrl = transformClipURL(url)
  return newUrl ?? url
}
const transformClipURL = (url: string): string | null => {
  if (url.includes('blog.naver.com')) {
    return extractNaverBlogURL(
      url.replace('https://blog.naver.com', 'https://m.blog.naver.com')
    )
  }
  return null
}
const transformBlogURL = (url: string): string | null => {
  if (url.includes('blog.naver.com')) {
    return extractNaverBlogURL(
      url.replace('https://blog.naver.com', 'https://m.blog.naver.com')
    )
  }
  if (url.includes('tistory.com')) {
    return extractTistoryBlogURL(url)
  }
  return null
}

export const extractTikTokURL = (url: string) => {
  const pattern = /https:\/\/www\.tiktok\.com\/@[^\/?]+/ // 쿼리 파라미터를 포함하지 않도록 수정
  const match = url.match(pattern)
  return match ? match[0] : url
}

export function useCountdown(targetDate: Date, active: boolean) {
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date()
    return difference > 0 ? Math.floor(difference / 1000) : 0
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft)

  useEffect(() => {
    if (active) {
      intervalRef.current = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [targetDate, active])

  const days = Math.floor(timeLeft / (24 * 60 * 60))
  const hours = Math.floor((timeLeft % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((timeLeft % (60 * 60)) / 60)
  const seconds = timeLeft % 60

  const formattedTime = `${days}일 ${String(hours).padStart(2, '0')} : ${String(
    minutes
  ).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`

  return { days, hours, minutes, seconds, formattedTime }
}

export const renderUserModalsByMyVideo = (userInfo: Me) => {
  if (!userInfo) return <NoUserModal />

  const isTrialOrRegularUser =
    userInfo.userType === UserType.체험 || userInfo.userType === UserType.USER

  if (isTrialOrRegularUser && !userInfo.usage?.isDone) {
    return <SecretModal />
  }

  return null
}

export const renderUserModalsByHome = (userInfo: Me) => {
  if (!userInfo) return <NoUserModalHome />

  const isTrialOrRegularUser =
    userInfo.userType === UserType.체험 || userInfo.userType === UserType.USER

  if (isTrialOrRegularUser && !userInfo.usage?.isDone) {
    return <SecretModalHome />
  }

  return null
}
export const renderUserModalsMyPage = (userInfo: Me) => {
  if (!userInfo) return null

  const isTrialOrRegularUser =
    userInfo.userType === UserType.체험 || userInfo.userType === UserType.USER

  if (isTrialOrRegularUser && !userInfo.usage?.isDone) {
    return <SecretModalMyPage />
  }

  return null
}

export const renderUserModalsVideoEditor = (userInfo: Me) => {
  if (!userInfo) return <NoUserModalHome />

  const isTrialOrRegularUser =
    userInfo.userType === UserType.체험 || userInfo.userType === UserType.USER

  if (isTrialOrRegularUser && !userInfo.usage?.isDone) {
    return <SecretModalMyPage />
  }

  return null
}
export const renderPaybackHome = (userInfo: Me) => {
  if (!userInfo) return <PayBackBannerHome />

  const isTrialOrRegularUser =
    userInfo.userType === UserType.체험 || userInfo.userType === UserType.USER

  if (isTrialOrRegularUser && !userInfo.usage?.isDone) {
    return <PayBackBannerHome />
  }

  return null
}

export const renderPaybackVideo = (userInfo: Me) => {
  if (!userInfo) return <PayBackBannerVideo />

  const isTrialOrRegularUser =
    userInfo.userType === UserType.체험 || userInfo.userType === UserType.USER

  if (isTrialOrRegularUser && !userInfo.usage?.isDone) {
    return <PayBackBannerVideo />
  }
  if (userInfo?.subscriptions?.subscriptionType === 'monthly') {
    return <PayBackBannerVideo />
  }

  return null
}

export const onLogOut = () => {
  removeLocalStorage('videoEditorData')
  removeLocalStorage('token')
  window.location.href = '/'
  setTimeout(() => {
    window.location.reload() // 그 후 새로 고침
  }, 1000) // 100ms의 딜레이를 주어 페이지 이동이 완료된 후 새로 고침
}

export const transitionPlanName = {
  프리미엄: 'PREMIUM',
  프로: 'PRO',
  라이트: 'LIGHT',
  체험: 'FREE',
}

export const handlPlanName = (
  userInfo: Me,
  userSubscriptionInfo: UserSubscriptionInfo | undefined
) => {
  let plan = ''
  switch (userInfo?.userType) {
    case UserType.USER:
      plan = '사용중인 플랜이 없어요'
      break
    case UserType.체험:
      plan = '무료 체험'
      break
    case UserType.라이트:
      plan = '라이트'
      break
    case UserType.프로:
      plan = '프로'
      break
    case UserType.프리미엄:
      plan = '프리미엄'
      break
    default:
      plan = ''
  }

  if (userInfo?.subscriptions?.subscriptionType === subscriptionType.ANNUAL) {
    plan += ' 1년'
  } else if (
    userInfo?.subscriptions?.subscriptionType === subscriptionType.MONTHLY
  ) {
    if (userInfo?.billing?.connected && userInfo?.userType !== UserType.체험) {
      console.log(plan, 's')
      plan += ` ${userSubscriptionInfo?.lastSubscriptions?.subscriptionTypeLabel}`
    } else if (!userInfo?.billing?.connected) {
      plan += ' 1개월'
    } else {
    }
  }

  return plan
}

/**
 *  @param {string} path - 로그인 혹은 회원가입후 돌아와야할 경로
 *  @param {function} prevSet - 전역상태 저장 함수
 */
export const savePrevPath = (path: string, prevSet: any) => {
  if (path) {
    prevSet({ prevUrl: path })
  }
}
