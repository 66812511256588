import React from 'react'

export const IntroTooltip = (props: any) => {
  const { setIntroTooltipStatus } = props

  return (
    <div
      className="flex flex-col p-[32px] gap-[10px] bg-white  w-[330px]  absolute border-[3px] border-main  rounded-[20px] top-8 md:left-44 z-50"
      onMouseLeave={() => {
        setIntroTooltipStatus(false)
      }}
    >
      <p className=" leading-[19.2px] text-base   text-[10px]">
        <span className="font-bold text-[20px]">인트로 화면 예시</span>
      </p>
      <p>
        인트로 화면은 상품 롱폼 영상의 맨 앞에 3초동안 보여주는 화면으로, 아래와
        같은 단일 스타일로 제공됩니다.
        <br />
        썸네일 내용으로 입력된 텍스트가 그대로 적용되며,{' '}
        <span className="text-error">
          ‘썸네일에 텍스트 넣지 않기'를 선택한 경우 인트로는 생성되지 않습니다.
        </span>{' '}
      </p>

      <img
        src="assets/img/introTooltip.png"
        alt=""
        className="w-[266px] h-[150px]"
      />
    </div>
  )
}
