import { useQuery } from '@tanstack/react-query'
import { getBillingTransactions } from '../../service/brandpay'

export function useGetBillingTransactions(page: number, pageSize: number) {
  return useQuery({
    queryKey: ['get-billing-transactions'],
    queryFn: () => getBillingTransactions(page, pageSize),
    refetchOnWindowFocus: true,
    retry: false,
    staleTime: 1000,
  })
}
