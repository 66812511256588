import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react'
import { Modal } from '../layout/Modal'
import { TransitionFactor } from '../../service/list'
import { PlanTag } from '../planTag'
import { Me } from '../../service/user'
import classNames from 'classnames'
import { transitionSort } from '../../utils/common'
import '../../App.css'
import { isMobile } from 'react-device-detect'
import { VideoComponent } from './VideoComponent'

interface TransitionModalProps {
  isTransitionModal: boolean
  setTransitionModal: Dispatch<SetStateAction<boolean>>
  copyTransitionList: TransitionFactor[]
  setCopyTransitionList: Dispatch<SetStateAction<TransitionFactor[] | []>>
  handleLoadedMetadata: (index: number) => void
  handlePlayButtonClick: (index: number) => void
  handleVideoEnded: (index: number) => void
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>
  videoPlaying: boolean[]
  userInfo: Me
  translatePlanName: (planName: string) => string
  Controller: any
  watch: any
  control: any
  setValue: any
  selectedTransitionIndex: number
  setSelectedTransitionIndex: Dispatch<SetStateAction<number>>
}

export const TransitionModal = ({
  children,
  isTransitionModal,
  setTransitionModal,
  copyTransitionList,
  setCopyTransitionList,
  handleLoadedMetadata,
  handlePlayButtonClick,
  handleVideoEnded,
  videoRefs,
  videoPlaying,
  userInfo,
  translatePlanName,
  Controller,
  watch,
  control,
  setValue,
  selectedTransitionIndex,
  setSelectedTransitionIndex,
}: PropsWithChildren<TransitionModalProps>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [sortKey, setSortKey] = useState<{
    key: string
    name: string
  }>({
    key: transitionSort[0].key,
    name: transitionSort[0].name,
  })
  // 정렬 함수
  const sortTransitions = (
    list: TransitionFactor[],
    sort: { key: 'id' | 'sort'; name: string }
  ) => {
    setSortKey(sort)
    const key = sort.key
    if (key === 'id') {
      const updateList = [...list].sort((a, b) => b[key] - a[key])
      return setCopyTransitionList(updateList)
    } else if (key === 'sort') {
      const updateList = [...list].sort((a, b) => a[key] - b[key])
      return setCopyTransitionList(updateList)
    } else {
      return setCopyTransitionList(list)
    }
  }

  return (
    <Modal isModal={isTransitionModal}>
      <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
        <div className="bg-white md:w-[816px] w-[335px] md:h-[670px] h-[630px] shadow-main rounded-[20px] md:p-8  p-4 flex flex-col   gap-4 ">
          <div className="flex flex-col h-[480px]  gap-4  ">
            <p className="flex justify-between items-center">
              <span className="font-bold text-[24px]">화면 전환 효과</span>
              <button
                onClick={() => {
                  setTransitionModal(false)
                }}
                className=""
              >
                <img
                  src="assets/img/Icon_x.png"
                  alt="icon_x"
                  height={40}
                  width={40}
                />
              </button>
            </p>
            <p className="w-full text-left">
              상품 영상에 상품이 여러개 포함된 경우, 각 상품 영상 사이에 삽입되는
              화면 전환 효과입니다.
            </p>
            <div className="flex justify-between items-center flex-col gap-4 scrollbar-visible h-[500px]">
              <div className="flex w-full justify-between items-center">
                <p>
                  총{` `}
                  <span className="font-bold text-main">
                    {copyTransitionList.length}개
                  </span>
                </p>
                <p
                  className="flex items-center relative"
                  onMouseOver={() => {
                    setIsOpen(true)
                  }}
                  onMouseLeave={() => {
                    setIsOpen(false)
                  }}
                >
                  <button>{sortKey.name}</button>
                  <img
                    src="assets/img/icon_arrow2.png"
                    alt="arrow"
                    className={`w-[12.8px] h-[8.25px] ml-2  transition-transform duration-500  ${
                      !isOpen ? '' : 'rotate-180'
                    }`}
                  />
                  {isOpen && (
                    <div className="flex flex-col w-[124px]  absolute -left-16 top-0 bg-white  border-[1px] border-[#D9DEE8] z-50 justify-center py-6 rounded-[4px]">
                      {transitionSort.map((el) => {
                        return (
                          <>
                            <button
                              className="flex items-center justify-center h-[46px] hover:bg-[#F6F6F6] text-[14px] font-[500] "
                              onClick={() => {
                                setIsOpen(false)
                                sortTransitions(copyTransitionList, el)
                              }}
                            >
                              {el.name}
                            </button>
                          </>
                        )
                      })}
                    </div>
                  )}
                </p>
              </div>
              <div className=" grid md:grid-cols-5  md:gap-x-[11.5px] md:gap-y-6   grid-cols-2  gap-x-[10px] gap-y-5">
                {copyTransitionList?.map((transition, index) => {
                  if (transition.name === '없음') {
                    return (
                      <div
                        className="flex  md:flex-col  relative w-[138px] h-[78px] justify-center"
                        key={index}
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }}
                        onClick={() => {
                          if (transition.usable === 1) {
                            setSelectedTransitionIndex(index)
                            setValue('transitionId', transition.id)
                          }
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            handleVideoEnded(index)
                          }}
                          className=" text-white font-bold z-50"
                        >
                          효과 없음
                        </button>
                        <div
                          className={classNames(
                            'absolute w-[138px] h-[78px] flex  justify-center',
                            {
                              'outline-[4px] outline outline-main':
                                transition.id === watch('transitionId'),
                            }
                          )}
                        >
                          <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                            <PlanTag
                              planName={translatePlanName(transition?.planName)}
                              className=""
                            />
                          </div>
                          <Controller
                            render={({ field }: any) => (
                              <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                <input
                                  type="checkbox"
                                  className="sr-only"
                                  checked={
                                    watch('transitionId') === transition.id
                                  }
                                  disabled={
                                    transition.usable === 0 ? true : false
                                  }
                                  onChange={() => {
                                    setSelectedTransitionIndex(index)
                                    setValue('transitionId', transition.id)
                                  }}
                                />
                                <div
                                  className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                    watch('transitionId') === transition.id
                                      ? 'bg-white border-main'
                                      : 'bg-white'
                                  }`}
                                >
                                  {watch('transitionId') === transition.id && (
                                    <div className="w-3 h-3 bg-main rounded-full"></div>
                                  )}
                                </div>
                              </label>
                            )}
                            name="transitionId"
                            control={control}
                          />
                        </div>
                      </div>
                    )
                  }

                  return (
                    <>
                      <div
                        className={`flex  md:flex-col  relative w-[138px] h-[78px]  video_${index}`}
                        key={index}
                        onMouseEnter={
                          !isMobile
                            ? () => handlePlayButtonClick(index)
                            : undefined
                        }
                        onMouseLeave={
                          !isMobile ? () => handleVideoEnded(index) : undefined
                        }
                        onClick={
                          isMobile
                            ? () => handlePlayButtonClick(index)
                            : undefined
                        }
                      >
                        <VideoComponent
                          videoRefs={videoRefs}
                          src={transition.path}
                          className={classNames({
                            ' outline outline-[4px] outline-main':
                              transition.id === watch('transitionId'),
                          })}
                          imagePath={transition.imagePath}
                          index={index}
                          selected={true}
                          sort={sortKey.key}
                          videoPlaying={videoPlaying}
                        />

                        <>
                          <div
                            style={{
                              backgroundColor:
                                watch('transitionId') === transition.id
                                  ? 'rgba(0, 0, 0, 0.5)'
                                  : '',
                            }}
                            className={classNames(
                              'absolute w-[138px] h-[78px] flex'
                            )}
                            onClick={() => {
                              if (transition.usable === 1) {
                                setSelectedTransitionIndex(index)
                                setValue('transitionId', transition.id)
                              }
                            }}
                          >
                            <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                              <PlanTag
                                planName={translatePlanName(
                                  transition?.planName
                                )}
                                className=""
                              />
                            </div>
                            <Controller
                              render={({ field }: any) => (
                                <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                  <input
                                    type="checkbox"
                                    className="sr-only"
                                    checked={
                                      watch('transitionId') === transition.id
                                    }
                                    disabled={
                                      transition.usable === 0 ? true : false
                                    }
                                    onChange={() => {
                                      setSelectedTransitionIndex(index)
                                      setValue('transitionId', transition.id)
                                    }}
                                  />
                                  <div
                                    className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                      watch('transitionId') === transition.id
                                        ? 'bg-white border-main'
                                        : 'bg-white'
                                    }`}
                                  >
                                    {watch('transitionId') ===
                                      transition.id && (
                                      <div className="w-3 h-3 bg-main rounded-full"></div>
                                    )}
                                  </div>
                                </label>
                              )}
                              name="transitionId"
                              control={control}
                            />
                          </div>
                        </>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="py-5">
            <button
              className="bg-main rounded-full w-full text-white font-bold h-[60px]"
              onClick={() => {
                setTransitionModal(false)
              }}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
