import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react'
import { Modal } from '../layout/Modal'
import { OutroFactor } from '../../service/list'
import { PlanTag } from '../planTag'
import { Me } from '../../service/user'
import classNames from 'classnames'
import { outroSort } from '../../utils/common'
import '../../App.css'
import { isMobile } from 'react-device-detect'
import { VideoComponent } from './VideoComponent'

interface OutroListModalProps {
  isOutroModal: boolean
  setOutroModal: Dispatch<SetStateAction<boolean>>
  copyOutroList: OutroFactor[]
  setCopyOutroList: Dispatch<SetStateAction<OutroFactor[] | []>>

  handlePlayButtonClick: (index: number) => void
  handleVideoEnded: (index: number) => void
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>
  videoPlaying: boolean[]
  userInfo: Me
  translatePlanName: (planName: string) => string
  Controller: any
  watch: any
  control: any
  setValue: any
  selectedOutroIndex: number
  setSelectedOutroIndex: Dispatch<SetStateAction<number>>
}

export const OutroListModal = ({
  children,
  isOutroModal,
  setOutroModal,
  copyOutroList,
  setCopyOutroList,

  handlePlayButtonClick,
  handleVideoEnded,
  videoRefs,
  videoPlaying,
  userInfo,
  translatePlanName,
  Controller,
  watch,
  control,
  setValue,
  selectedOutroIndex,
  setSelectedOutroIndex,
}: PropsWithChildren<OutroListModalProps>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [sortKey, setSortKey] = useState<{
    key: string
    name: string
  }>({
    key: outroSort[0].key,
    name: outroSort[0].name,
  })
  // 정렬 함수
  const sortOutros = (
    list: OutroFactor[],
    sort: { key: 'id' | 'sort'; name: string }
  ) => {
    setSortKey(sort)
    const key = sort.key
    if (key === 'id') {
      const updateList = [...list].sort((a, b) => b[key] - a[key])
      return setCopyOutroList(updateList)
    } else if (key === 'sort') {
      const updateList = [...list].sort((a, b) => a[key] - b[key])
      return setCopyOutroList(updateList)
    } else {
      return setCopyOutroList(list)
    }
  }

  return (
    <Modal isModal={isOutroModal}>
      <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
        <div className="bg-white md:w-[816px] w-[335px] md:h-[670px] h-[630px] shadow-main rounded-[20px] md:p-8  p-4 flex flex-col   gap-4 ">
          <div className="flex flex-col h-[480px]  gap-4  ">
            <p className="flex justify-between items-center">
              <span className="font-bold text-[24px] text-left">
                영상 마지막 구독 & 좋아요 요청 화면
              </span>
              <button
                onClick={() => {
                  setOutroModal(false)
                }}
                className=""
              >
                <img
                  src="assets/img/Icon_x.png"
                  alt="icon_x"
                  height={40}
                  width={40}
                />
              </button>
            </p>
            <p className="w-full text-left">
              영상의 마지막에 구독 & 좋아요 등을 요청하는 3~5초 내외의 아웃트로
              영상 클립입니다. <br />
              효과 선택시{' '}
              <span className="font-bold text-main">상품 영상 롱폼</span>에
              적용됩니다. (숏폼에는 적용되지 않아요)
            </p>
            <div className="flex justify-between items-center flex-col gap-4 scrollbar-visible h-[500px]">
              <div className="flex w-full justify-between items-center">
                <div className="flex gap-2 items-center">
                  <p>
                    총{` `}
                    <span className="font-bold text-main">
                      {copyOutroList.length}개
                    </span>
                  </p>
                  <p className="text-error text-[14px]">
                    신규 기능 할인 이벤트중
                  </p>
                </div>
                {/* <p
                  className="flex items-center relative"
                  onMouseOver={() => {
                    setIsOpen(true)
                  }}
                  onMouseLeave={() => {
                    setIsOpen(false)
                  }}
                >
                  <button>{sortKey.name}</button>
                  <img
                    src="assets/img/icon_arrow2.png"
                    alt="arrow"
                    className={`w-[12.8px] h-[8.25px] ml-2  outro-transform duration-500  ${
                      !isOpen ? '' : 'rotate-180'
                    }`}
                  />
                  {isOpen && (
                    <div className="flex flex-col w-[124px]  absolute -left-16 top-0 bg-white  border-[1px] border-[#D9DEE8] z-50 justify-center py-6 rounded-[4px]">
                      {outroSort.map((el) => {
                        return (
                          <>
                            <button
                              className="flex items-center justify-center h-[46px] hover:bg-[#F6F6F6] text-[14px] font-[500] "
                              onClick={() => {
                                setIsOpen(false)
                                sortOutros(copyOutroList, el)
                              }}
                            >
                              {el.name}
                            </button>
                          </>
                        )
                      })}
                    </div>
                  )}
                </p> */}
              </div>
              <div className=" grid md:grid-cols-5  md:gap-x-[11.5px] md:gap-y-6   grid-cols-2  gap-x-[10px] gap-y-5">
                {copyOutroList?.map((outro, index) => {
                  if (outro.name === '없음') {
                    return (
                      <div
                        className="flex  md:flex-col  relative w-[138px] h-[78px] justify-center"
                        key={index}
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }}
                        onClick={() => {
                          setSelectedOutroIndex(index)
                          setValue('outroId', outro.id)
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            handleVideoEnded(index)
                          }}
                          className=" text-white font-bold z-50"
                        >
                          효과 없음
                        </button>
                        <div
                          className={classNames(
                            'absolute w-[138px] h-[78px] flex  justify-center',
                            {
                              'outline-[4px] outline outline-main':
                                outro.id === watch('outroId'),
                            }
                          )}
                        >
                          <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                            <PlanTag
                              planName={translatePlanName(outro?.planName)}
                              className=""
                            />
                          </div>
                          <Controller
                            render={({ field }: any) => (
                              <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                <input
                                  type="checkbox"
                                  className="sr-only"
                                  checked={watch('outroId') === outro.id}
                                  disabled={
                                    !userInfo ||
                                    userInfo?.userType === 'normalUser' ||
                                    userInfo?.userType === 'lightUser'
                                  }
                                  onChange={() => {
                                    setSelectedOutroIndex(index)
                                    setValue('outroId', outro.id)
                                  }}
                                />
                                <div
                                  className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                    watch('outroId') === outro.id
                                      ? 'bg-white border-main'
                                      : 'bg-white'
                                  }`}
                                >
                                  {watch('outroId') === outro.id && (
                                    <div className="w-3 h-3 bg-main rounded-full"></div>
                                  )}
                                </div>
                              </label>
                            )}
                            name="outroId"
                            control={control}
                          />
                        </div>
                      </div>
                    )
                  }

                  return (
                    <>
                      <div
                        className={`flex  md:flex-col  relative w-[138px] h-[78px]  video_${index}`}
                        key={index}
                        onMouseEnter={
                          !isMobile
                            ? () => handlePlayButtonClick(index)
                            : undefined
                        }
                        onMouseLeave={
                          !isMobile ? () => handleVideoEnded(index) : undefined
                        }
                        onClick={
                          isMobile
                            ? () => handlePlayButtonClick(index)
                            : undefined
                        }
                      >
                        <VideoComponent
                          videoRefs={videoRefs}
                          src={outro.path}
                          className={classNames({
                            ' outline outline-[4px] outline-main':
                              outro.id === watch('outroId'),
                          })}
                          imagePath={outro.imagePath}
                          index={index}
                          selected={true}
                          sort={sortKey.key}
                          videoPlaying={videoPlaying}
                        />

                        <>
                          <div
                            style={{
                              backgroundColor:
                                watch('outroId') === outro.id
                                  ? 'rgba(0, 0, 0, 0.5)'
                                  : '',
                            }}
                            className={classNames(
                              'absolute w-[138px] h-[78px] flex'
                            )}
                            onClick={() => {
                              setSelectedOutroIndex(index)
                              setValue('outroId', outro.id)
                            }}
                          >
                            <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                              <PlanTag
                                planName={translatePlanName(outro?.planName)}
                                className=""
                              />
                            </div>
                            <Controller
                              render={({ field }: any) => (
                                <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                  <input
                                    type="checkbox"
                                    className="sr-only"
                                    checked={watch('outroId') === outro.id}
                                    disabled={
                                      !userInfo ||
                                      userInfo?.userType === 'normalUser' ||
                                      userInfo?.userType === 'lightUser'
                                    }
                                    onChange={() => {
                                      setSelectedOutroIndex(index)
                                      setValue('outroId', outro.id)
                                    }}
                                  />
                                  <div
                                    className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                      watch('outroId') === outro.id
                                        ? 'bg-white border-main'
                                        : 'bg-white'
                                    }`}
                                  >
                                    {watch('outroId') === outro.id && (
                                      <div className="w-3 h-3 bg-main rounded-full"></div>
                                    )}
                                  </div>
                                </label>
                              )}
                              name="outroId"
                              control={control}
                            />
                          </div>
                        </>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="py-5">
            <button
              className="bg-main rounded-full w-full text-white font-bold h-[60px]"
              onClick={() => {
                setOutroModal(false)
              }}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
