import { useMutation } from '@tanstack/react-query'
import {
  getBillingCustomerKey,
  putBillingEnable,
  putBillingMethods,
} from '../../../service/brandpay'

function usePutBillingEnable() {
  return useMutation({
    mutationKey: ['put-billing-methods-enable'],
    mutationFn: putBillingEnable,
  })
}

export default usePutBillingEnable
