import { useQuery } from '@tanstack/react-query'
import { getAnimationList, getBackgroundList, getBgm } from '../../service/list'
import { getUserSubscriptionInfo } from '../../service/user'

function useGetUserSubscriptionInfo() {
  return useQuery({
    queryKey: ['get-user-subscription'],
    queryFn: () => getUserSubscriptionInfo(),
    staleTime: 6 * 1000,
  })
}

export default useGetUserSubscriptionInfo
