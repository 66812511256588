import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useConfirmPayment from '../hooks/query/useConfirmPayment'
import { getUtmLocalStorage } from '../utils/common'
import { useSaveUtm } from '../hooks/query/useSaveUtm'
import { planPurchaseBtnClick, purchasePlan } from '../utils/metaAd'
import { Spinner } from '../components/atom/Spinner'

import useGetBillingCustomerKey from '../hooks/query/brandpay/useGetBillingCustomerKey'
import useUserStore from '../store/useUserStore'
import useUsageStore from '../store/useUsageStore'
import usePlanStore from '../store/usePlanStore'
import useConfirmPoint from '../hooks/query/point/useConfirmPoint'

export const PaymentSuccess = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const userInfo = useUserStore((state: any) => state.userInfo)
  const { mutate: customerkeyMutate } = useGetBillingCustomerKey()
  const [customerKey, setCustomerKey] = useState<string>('')
  const [isBrandPaymentModal, setBrandPaymentModal] = useState<boolean>(false)

  const paymentKey = urlParams.get('paymentKey')
  const subscriptionType = urlParams.get('subscriptionType')
  const orderId = urlParams.get('orderId')
  const amount = urlParams.get('amount')
  const path = urlParams.get('path')
  const planId = urlParams.get('planId')
  const paymentType = urlParams.get('type')
  const close = urlParams.get('close')
  const refetch = useUserStore((state: any) => state.refetch)
  const planRefetch = usePlanStore((state: any) => state.planRefetch)

  const setPlanRefetch = usePlanStore((state: any) => state.setRefetch)
  const navigate = useNavigate()
  const { mutate: confirmPaymentMutate, isPending: planPending } =
    useConfirmPayment()
  const { mutate: confirmPointMutate, isPending: pointPending } =
    useConfirmPoint()
  const { mutate: saveUtmMutate } = useSaveUtm()
  const [paymentAmount, setPaymentAmount] = useState(0)
  const [autoClose, setAutoClose] = useState<string | null>(null)

  useEffect(() => {
    if (paymentKey && orderId && amount) {
      const confirmPaymentParams = {
        paymentKey,
        orderId,
        amount: Number(amount),
      }

      if (paymentType === 'point') {
        confirmPointMutate(confirmPaymentParams, {
          onSuccess: (data: any) => {
            if (window.gtag) {
              // <!-- Event snippet for 캐시충전하기 conversion page -->
              window.gtag('event', 'conversion', {
                send_to: 'AW-677452397/xmAXCLLB3IwaEO20hMMC',
                value: 1.0,
                currency: 'KRW',
                transaction_id: '',
              })
            } else {
              console.error('gtag is not defined')
            }
            if (close === 'null') {
              setAutoClose(null)
            } else {
              setAutoClose(close)
            }

            setPaymentAmount(Number(amount))
            navigate(`${window.location.pathname}?type=${paymentType}`, {
              replace: true,
            })
          },
          onError: (e: any) => {
            navigate(
              `/payment-fail?path=my-cash-dashboard&type=${paymentType}&message=${
                e?.response?.data?.message ?? ''
              }`
            )
          },
        })
      }
      if (paymentType === 'plan') {
        confirmPaymentMutate(confirmPaymentParams, {
          onSuccess: (data) => {
            console.log('연간 plan 결제 성공:', planId)
            if (subscriptionType === 'annual') {
              if (window.gtag) {
                if (Number(planId) === 3) {
                  console.log('프리미엄')
                  // <!-- Event snippet for 캐시충전하기 conversion page -->
                  window.gtag('event', 'conversion', {
                    send_to: 'AW-677452397/WhajCKnB3IwaEO20hMMC',
                    value: 1.0,
                    currency: 'KRW',
                    transaction_id: '',
                  })
                } else if (Number(planId) === 2) {
                  // <!-- Event snippet for 캐시충전하기 conversion page -->
                  window.gtag('event', 'conversion', {
                    send_to: 'AW-677452397/4xgXCKbB3IwaEO20hMMC',
                    value: 1.0,
                    currency: 'KRW',
                    transaction_id: '',
                  })
                } else if (Number(planId) === 1) {
                  // <!-- Event snippet for 캐시충전하기 conversion page -->
                  window.gtag('event', 'conversion', {
                    send_to: 'AW-677452397/w5kqCKPB3IwaEO20hMMC',
                    value: 1.0,
                    currency: 'KRW',
                    transaction_id: '',
                  })
                }
              } else {
                console.error('gtag is not defined')
              }
            }

            setPaymentAmount(Number(amount))

            navigate(window.location.pathname, { replace: true })
          },
          onError: (e: any) => {
            navigate(
              `/payment-fail?path=price-guide&type=${paymentType}&message=${
                e?.response?.data?.message ?? ''
              }`
            )
          },
        })
      }
    } else if (amount) {
      if (subscriptionType === 'monthly') {
        console.log('월 구독 plan 결제 성공:', planId)
        if (window.gtag) {
          if (Number(planId) === 3) {
            console.log('프리미엄')
            // <!-- Event snippet for 캐시충전하기 conversion page -->
            window.gtag('event', 'conversion', {
              send_to: 'AW-677452397/d3y7CKDB3IwaEO20hMMC',
              value: 1.0,
              currency: 'KRW',
              transaction_id: '',
            })
          } else if (Number(planId) === 2) {
            // <!-- Event snippet for 캐시충전하기 conversion page -->
            window.gtag('event', 'conversion', {
              send_to: 'AW-677452397/9xdDCJ3B3IwaEO20hMMC',
              value: 1.0,
              currency: 'KRW',
              transaction_id: '',
            })
          } else if (Number(planId) === 1) {
            // <!-- Event snippet for 캐시충전하기 conversion page -->
            window.gtag('event', 'conversion', {
              send_to: 'AW-677452397/YPKYCJrB3IwaEO20hMMC',
              value: 1.0,
              currency: 'KRW',
              transaction_id: '',
            })
          }
        } else {
          console.error('gtag is not defined')
        }
      }
      setPaymentAmount(Number(amount))
      navigate(window.location.pathname, { replace: true })
    }
  }, [paymentKey, orderId, amount])

  useEffect(() => {
    if (planRefetch) {
      planRefetch()
    }
    if (refetch) {
      refetch()
    }
  }, [])

  useEffect(() => {
    console.log(autoClose, 'autoClose')
    if (autoClose) {
      window.close()
    }
  }, [autoClose])
  return (
    <div className=" relative   md:max-w-[816px] md:min-h-[100vh] max-w-[375px] my-0 mx-auto flex flex-col px-[16px]">
      <div className="flex justify-center font-bold text-[48px]">
        <p>결제 완료</p>
      </div>
      {pointPending || planPending ? (
        <>
          {' '}
          <Spinner />
        </>
      ) : (
        <>
          <div className="flex flex-col gap-4 px-8 py-6 font-bold bg-[#F6F8FB] rounded-[20px] mt-8">
            <div className="flex justify-between">
              <p>금액</p>
              <p>{(paymentAmount * (10 / 11)).toLocaleString()}원</p>
            </div>
            <div className="flex justify-between">
              <p>VAT(부가세10%)</p>
              <p>{(paymentAmount * (1 / 11)).toLocaleString()}원</p>
            </div>
          </div>
          <div className="flex flex-col gap-4 px-8 py-5 font-bold bg-[#AFECEC] rounded-[20px] mt-2">
            <div className="flex justify-between">
              <p>총 결제금액</p>
              <p>{paymentAmount.toLocaleString()}원</p>
            </div>
          </div>
          <div className="flex flex-col gap-4 font-bold text-white mt-8 ">
            <div className="flex justify-center gap-4 w-full">
              {paymentType === 'point' && (
                <button
                  className="bg-[#735CFF] w-[400px] h-[60px] flex justify-center items-center rounded-full"
                  onClick={() => {
                    navigate('/short-video-editor')
                  }}
                >
                  컨텐츠 영상 만들러 가기
                </button>
              )}

              {paymentType === 'plan' && (
                <button
                  className="bg-main w-[400px] h-[60px] flex justify-center items-center rounded-full"
                  onClick={() => {
                    planRefetch()
                    refetch()
                    navigate('/video-editor')
                  }}
                >
                  상품 영상 만들러 가기
                </button>
              )}
            </div>
          </div>
        </>
      )}{' '}
    </div>
  )
}
