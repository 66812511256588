import { useMutation } from '@tanstack/react-query'
import { getBillingCustomerKey } from '../../../service/brandpay'

function useGetBillingCustomerKey() {
  return useMutation({
    mutationKey: ['get-billing-customerkey'],
    mutationFn: getBillingCustomerKey,
  })
}

export default useGetBillingCustomerKey
