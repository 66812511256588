import React from 'react'
import { My } from '../components/My'
import { FAQ } from '../components/FAQ'
import { isMobile } from 'react-device-detect'
import useUserStore from '../store/useUserStore'

export const QnA = () => {
  const userInfo = useUserStore((state: any) => state.userInfo)

  return (
    <div className="md:max-w-[816px] max-w-[375px]  my-0 mx-auto flex flex-col items-center gap-6 ">
      <div className="md:w-full w-[343px] ">
        <div className="flex justify-center mb-[24px]">
          <p className="font-bold text-[24px] md:text-[40px]">자주 묻는 질문</p>
        </div>
        <FAQ />
      </div>
    </div>
  )
}
