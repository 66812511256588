import React from 'react'
import { useNavigate } from 'react-router-dom'

export const CancelSubscriptionConfirm = () => {
  const navigate = useNavigate()

  const moveSubscriptionPage = () => {
    navigate('/subscription?page=1&pageSize=10')
  }
  return (
    <div className=" md:w-[816px] w-[375px] min-h-[100vh] my-0 mx-auto flex flex-col items-center px-4 gap-6">
      <div className="md:w-full w-[343px] flex flex-col gap-6 ">
        <div className="md:w-full w-[335px] font-bold text-[24px] relative flex justify-center">
          <p className="">구독 해지 완료</p>
          <p className=" absolute left-0" onClick={moveSubscriptionPage}>
            {'<'}
          </p>
        </div>
      </div>
      <p className="text-center text-[18px]">
        구독 해지가 완료되었습니다. <br />
        패스트컷은 언제나 당신을 기다리며
        <br />
        더 좋은 서비스를 준비하겠습니다.
        <br />
        감사합니다.
      </p>

      <button
        className="bg-main rounded-full  font-bold text-white w-[343px] h-[44px]"
        onClick={moveSubscriptionPage}
      >
        확인
      </button>
    </div>
  )
}
