import { UserInfo } from 'os'
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { Me } from '../../service/user'
import {
  getLocalStorage,
  getUtmLocalStorage,
  UserType,
} from '../../utils/common'
import { usePostExperiencePlan } from '../../hooks/query/usePostExperiencePlan'

import {
  adFreeExperienceBtnClick,
  freeExperienceBtnClick,
} from '../../utils/metaAd'
import { useSaveUtm } from '../../hooks/query/useSaveUtm'
import useUserStore from '../../store/useUserStore'
interface GuideModalProps {
  isGuideModal: boolean
  setGuideModal: Dispatch<SetStateAction<boolean>>
  userInfo: Me
}
export const GuideModal = ({
  children,
  isGuideModal,
  setGuideModal,
  userInfo,
}: PropsWithChildren<GuideModalProps>) => {
  const navigate = useNavigate()
  const refetch = useUserStore((state: any) => state.refetch)
  const { mutate: experiencePlanMutate } = usePostExperiencePlan()
  const { mutate: saveUtmMutate } = useSaveUtm()
  const paymentFreePlan = () => {
    if (!userInfo) {
      navigate('/sign-up')
      return
    } else if (userInfo?.userType === UserType.USER) {
      const utmData = getUtmLocalStorage()
      if (utmData?.utmSource) {
        utmData.trackType = 'FREE_EXPERIENCE'
        saveUtmMutate(utmData, {
          onSuccess: () => {},
          onError: () => {},
        })
        adFreeExperienceBtnClick()
      } else {
        freeExperienceBtnClick()
      }

      experiencePlanMutate('', {
        onSuccess: (data) => {
          setGuideModal(false)
          refetch()
          navigate('/video-editor?time=1')
        },
        onError: () => {
          setGuideModal(false)
        },
      })
    } else if (userInfo?.userType === UserType.체험) {
      setGuideModal(false)
    }
  }

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
      <div className="flex flex-col overflow-y-auto pb-[200px]">
        <div className="md:w-[440px] flex flex-col bg-white px-[20px] py-[32px] gap-[24px] rounded-t-[40px] ">
          <div className="flex flex-col">
            <p className=" text-[24px] font-bold">
              <span className="text-href"> 🙌 무료 체험 시작!</span> <br />
              아래 내용을 꼭 기억해주세요
            </p>
          </div>
          <div className="flex flex-col bg-[#F6F8FB] p-[20px] items-start justify-center text-left  rounded-[20px]">
            <ul className=" list-decimal ml-4">
              <li className="font-bold">
                {' '}
                쿠팡 파트너스나 알리 익스프레스 어필리에이트 계정 준비하셨나요?
              </li>

              <div className="flex flex-col bg-white w-full justify-center  mt-1  p-[7px]">
                {' '}
                <p className="flex gap-2 md:text-base text-[10px] w-[] ">
                  <img
                    src="assets/img/coupang_icon.png"
                    alt="coupang"
                    width={24}
                    height={24}
                    className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
                  />
                  <a
                    href="https://blog.fastseller.shop/coupangpartnersguide/"
                    target="_blank"
                    rel="noreferrer"
                    className="md:text-base text-[10px] text-href"
                  >
                    {' '}
                    {`쿠팡 파트너스 가이드 >`}
                  </a>
                </p>
                <p className="flex gap-2 md:text-base text-[10px] items-center mt-[8px]">
                  <img
                    src="assets/img/ali_icon.png"
                    alt="ali"
                    className="md:w-[24px] md:h-[24px] w-[16px] h-[16px] "
                  />
                  <a
                    href="https://blog.fastseller.shop/aliexpressaffiliatteguide/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-href"
                  >{`알리익스프레스 어필리에이트 가이드 >`}</a>
                </p>
              </div>

              <li className="mt-[24px] font-bold">
                '자동 업로드 설정' 페이지에서 유튜브 자동업로드에 필요한 설정을
                해주세요
              </li>

              <li className="mt-[24px] font-bold">
                상품 링크, 상품명, 제목 등의 정보를 넣고 영상 생성을 요청하세요.
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-between w-full bg-white rounded-b-[40px]">
          <button
            className="w-full h-[60px] bg-main rounded-b-[40px] text-white font-bold "
            onClick={paymentFreePlan}
          >
            영상 생성 시작
          </button>
        </div>
      </div>
    </div>
  )
}
