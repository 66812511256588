import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

interface VideoComponentProps {
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>
  src: string
  index: number
  selected: boolean
  className?: string
  sort?: string
  imagePath?: string
  videoPlaying: boolean[]
}
export const VideoComponent: React.FC<VideoComponentProps> = ({
  videoRefs,
  src,
  index,
  selected,
  className,
  sort,
  imagePath,
  videoPlaying,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false) // 이미지 로딩 상태
  const [isLoading, setIsLoading] = useState(true) // 전체 로딩 상태 (스켈레톤 여부)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const videoElement = videoRefs.current?.[index] // null 체크 추가
        if (entry.isIntersecting && videoElement) {
          videoElement.load() // 비디오 로딩
          console.log(`${index} 비디오 로딩`)
        }
      },
      { threshold: 0.3 } // 비율 30% 이상 보일 때
    )

    const videoElement = videoRefs.current?.[index] // null 체크 추가
    if (videoElement) {
      observer.observe(videoElement) // 비디오가 화면에 나타나면 로드
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement) // 컴포넌트가 unmount될 때 observer 해제
      }
    }
  }, [videoRefs, index])
  const handleImageLoad = () => {
    setIsImageLoaded(true) // 이미지 로딩 완료
    setIsLoading(false) // 스켈레톤 숨김
  }

  return (
    <div className="relative w-[138px] h-[78px]">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-300 overflow-hidden">
          <div className="h-full w-full bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 animate-skeleton"></div>
        </div>
      )}

      {imagePath && !videoPlaying[index] && (
        <img
          src={imagePath}
          className={classNames(`${className} w-[138px] h-[78px]`)}
          loading="lazy"
          alt=""
          onLoad={() => setIsLoading(false)}
          onLoadedData={() => setIsLoading(false)}
        />
      )}

      {videoPlaying[index] && (
        <video
          ref={(ref) => {
            if (ref) {
              videoRefs.current[index] = ref
            }
          }}
          playsInline
          src={src}
          key={index}
          loop
          autoPlay
          muted
          className={classNames(`${className} w-min-[138px] h-[78px]`)}
        />
      )}
    </div>
  )
}
