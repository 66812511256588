import React, { PropsWithChildren, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { FormValues } from '../../../utils/type'
import { Me } from '../../../service/user'
import { CircularCheckbox } from '../../CircularCheckbox'
import { IntroTooltip } from '../IntroTooltip'

interface IntroDisplayProps {
  watch: (name: 'includeIntro', defaultValue?: boolean | undefined) => boolean
  setValue: (
    name: 'includeIntro',
    value: boolean,
    options?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
          shouldTouch: boolean
        }>
      | undefined
  ) => void
  control: Control<FormValues, any>
  userInfo: Me
}
export const IntroDisplay = ({
  watch,
  setValue,
  control,
  userInfo,
}: PropsWithChildren<IntroDisplayProps>) => {
  const [introTooltipStatus, setIntroTooltipStatus] = useState<boolean>(false)
  return (
    <div className="price  my-[40px]">
      <div className="flex flex-col  md:mb-[16px] gap-4 relative ">
        <p className="flex items-center font-bold text-[18px]">
          영상 시작점 인트로 화면
          <span
            className="text-[white] text-[14px] bg-[#8C9DC2] px-3 py-1 rounded-[4px] ml-2"
            onMouseOver={() => {
              setIntroTooltipStatus((prev) => !prev)
            }}
            onMouseLeave={() => {
              setIntroTooltipStatus((prev) => !prev)
            }}
          >
            예시
          </span>
        </p>
        {introTooltipStatus && (
          <IntroTooltip setIntroTooltipStatus={setIntroTooltipStatus} />
        )}
        <div className="grid grid-cols-2 cursor-pointer">
          {[
            { name: '넣기', value: true },
            { name: '빼기', value: false },
          ].map((el, index) => {
            return (
              <div>
                <label
                  onClick={() => {
                    setValue('includeIntro', el.value)
                  }}
                >
                  <Controller
                    render={({ field }) => (
                      <CircularCheckbox
                        {...field}
                        id={index}
                        checked={watch('includeIntro') === el.value}
                        label={el.name}
                        onChange={() => {
                          setValue('includeIntro', el.value)
                        }}
                        disabled={
                          !userInfo || userInfo?.userType === 'normalUser'
                        }
                      />
                    )}
                    name="includeIntro"
                    control={control}
                  />
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
